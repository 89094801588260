html,
body,
#root {
  height: 100%;
}

.home-button.upload a .image-btn {
  background-image: url('../img/icon_upload.svg');
}

.form-row.align-left-sd {
  text-align: left;
}

.icon-right {
  margin-left: 10px;
}

.icon-left {
  margin-right: 10px;
}

.home-button.delivery-ocr a .image-btn {
  background-image: url('../imgPWA/delivery.svg');
}

.home-button.delivery-ocr:hover a .image-btn {
  background-image: url('../imgPWA/delivery-white.svg');
}

.home-button.smartphone-docs a .image-btn {
  background-image: url('../img/smartphone_docs_icon.svg');
}

.home-button.smartphone-docs:hover a .image-btn {
  background-image: url('../img/smartphone_docs_icon_white.svg');
}

.home-button.ocr-module a .image-btn {
  background-image: url('../img/ocr_module_icon_red.svg');
}

.home-button.ocr-module:not(.disabled):hover a .image-btn {
  background-image: url('../img/ocr_module_icon_white.svg');
}

.home-button.strength-module a .image-btn {
  background-image: url('../img/strength_module_icon_red.svg');
}

.home-button.strength-module:not(.disabled):hover a .image-btn {
  background-image: url('../img/strength_module_icon_white.svg');
}

.home-button.thermalControl a .image-btn {
  background-image: url('../img/no_hood_icon_red.svg');
}

.home-button.thermalControl:hover a .image-btn {
  background-image: url('../img/no_hood_icon_white.svg');
}

.home-button.hoodedControl a .image-btn {
  background-image: url('../img/hooded_icon_red.svg');
}

.home-button.hoodedControl:hover a .image-btn {
  background-image: url('../img/hooded_icon_white.svg');
}

.home-button.mixtures a .image-btn {
  background-image: url('../img/mixture_red.svg');
}

.home-button.mixtures:hover a .image-btn {
  background-image: url('../img/mixture_white.svg');
}

.home-button.monitorings a .image-btn {
  background-image: url('../img/monitoring_red.svg');
}

.home-button.monitorings:hover a .image-btn {
  background-image: url('../img/monitoring_white.svg');
}

.home-button.devices a .image-btn {
  background-image: url('../img/devices_red.svg');
}

.home-button.devices:hover a .image-btn {
  background-image: url('../img/devices_white.svg');
}

.iconGreen {
  color: green;
}

.iconRed {
  color: red;
}

.content {
  height: auto !important;
}

.wrapper {
  padding-bottom: 120px !important;
}

.footer {
  z-index: 1 !important;
}

@media (max-width: 520px) {
  .antdDatePicker.ant-picker-dropdown {
    left: 0 !important;
    right: 0 !important;
    margin-left: auto;
    margin-right: auto;
    top: 316px !important;
    width: 430px;
  }
}

.ant-picker-outlined,
.ant-input-outlined {
  border: 0;
}

.ant-picker-outlined:not(.ant-picker-disabled),
.ant-input-outlined:not(.ant-input-disabled) {
  background: #f1f1f1;
}

.ant-picker-cell-inner:not(.month):not(.year) {
  border-radius: 50% !important;

  &:before {
    border-radius: 50% !important;
  }
}

.antdDatePicker .ant-picker-cell-inner.month,
.antdDatePicker .ant-picker-cell-inner.year {
  text-transform: capitalize;
}

.antdDatePicker .ant-picker-time-panel-cell-inner {
  width: 30px !important;
  border-radius: 50% !important;
  padding-inline-start: 0px !important;
  text-align: center !important;
}

.antdTimePicker {
  .ant-picker-now {
    display: none;
  }

  .ant-picker-panel {
    width: 100%;

    .ant-picker-time-panel-cell {
      display: flex;
      justify-content: center;
    }
  }

  .ant-picker-time-panel-cell-inner {
    width: 30px !important;
    border-radius: 50% !important;
    padding-inline-start: 0px !important;
    text-align: center !important;
  }
}

.ant-btn {
  border-radius: 0;

  span {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.9em;
  }
}

.ant-btn-primary {
  background: red;
  border-color: red;

  &:hover {
    border-color: red;
  }
}

.home-button.disabled {
  opacity: .5;
}

.home-button.disabled a:hover {
  cursor: not-allowed !important;
  background-color: #ddd;
}

.home-button.disabled a:hover span {
  color: black
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}