@media (max-width: 550px) {
  .adminButton span {
    display: none;
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header>* {
  flex: 1 1 30%;
}

header>div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 35px;
}

header>div:last-child>button:not(:first-child) {
  margin: 0 0 0 15px;
}

header>div:nth-child(2) {
  display: flex;
  min-width: 30%;
  justify-content: center;
  align-items: center;
}

@media (max-width: 550px) {
  header>div:first-child {
    flex: 0 0 100px;
    padding-right: 15px;

    img {
      width: 100px;
    }
  }

  header>div:last-child {
    flex: 0 0 15%;
    padding-right: 10px;
  }
}

.userZone {
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    color: #232323;
  }
}