@mixin text() {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;

  span {
    text-transform: none;
  }
}

.wrapper {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div {
    flex: 0 0;
  }
  .header {
    & > div {
      flex: 1;
    }

    & > div > div > dl {
      margin-right: 20px;
      // flex: 1 1 40%;
    }

    & > div:last-child {
      margin-left: auto;
      // flex: 0 0 30%;

      & > div:last-child > dl {
        flex: 1;
      }
    }

    dt {
      @include text;
      & {
        margin: 0px;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }
    }

    dd {
      color: #666;
    }

    //margin-bottom: 40px;
  }

  .calibration {
    margin-top: 20px;
    display: flex;
    dl {
      margin-bottom: 0px;
    }
  }

  .parameters {
    flex: 1 1 375px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
  }

  .tabs {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
  }
}
.spin {
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
