/* ************************ START Login Style ******************* */
@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-extralight.eot');
  src:
    url('../fonts/acciona-extralight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-extralight.woff2') format('woff2'),
    url('../fonts/acciona-extralight.woff') format('woff'),
    url('../fonts/acciona-extralight.ttf') format('truetype'),
    url('../fonts/acciona-extralight.svg#accionaextralight') format('svg');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-light.eot');
  src:
    url('../fonts/acciona-light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-light.woff2') format('woff2'),
    url('../fonts/acciona-light.woff') format('woff'),
    url('../fonts/acciona-light.ttf') format('truetype'),
    url('../fonts/acciona-light.svg#accionalight') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-font.eot');
  src:
    url('../fonts/acciona-font.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-font.woff2') format('woff2'),
    url('../fonts/acciona-font.woff') format('woff'),
    url('../fonts/acciona-font.ttf') format('truetype'),
    url('../fonts/acciona-font.svg#acciona-fontfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-regular.eot');
  src:
    url('../fonts/acciona-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-regular.woff2') format('woff2'),
    url('../fonts/acciona-regular.woff') format('woff'),
    url('../fonts/acciona-regular.ttf') format('truetype'),
    url('../fonts/acciona-regular.svg#accionaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-medium.eot');
  src:
    url('../fonts/acciona-medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-medium.woff2') format('woff2'),
    url('../fonts/acciona-medium.woff') format('woff'),
    url('../fonts/acciona-medium.ttf') format('truetype'),
    url('../fonts/acciona-medium.svg#accionamedium') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Acciona Icons';
  src: url('../fonts/testnewicons.eot');
  src:
    url('../fonts/testnewicons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/testnewicons.woff2') format('woff2'),
    url('../fonts/testnewicons.woff') format('woff'),
    url('../fonts/testnewicons.ttf') format('truetype'),
    url('../fonts/testnewicons.svg#icons2regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.login body {
  font-size: 15px;
  background-color: #f1f1f1;
  padding: 0px;
  font-family: 'Acciona Font', sans-serif;
  font-weight: normal;
}
a:focus {
  outline: none;
  outline-offset: 0;
}
a:focus,
a:hover {
  color: initial;
  text-decoration: none;
}
a,
a:visited,
a:active {
  color: white;
}
.btn,
.btn:active,
.btn:focus,
.btn:visited {
  outline: none;
}
img {
  max-width: 100%;
  height: auto;
}
button:active > *,
button:focus > * {
  position: relative;
  top: 0;
  left: 0;
}
/**/
.login {
  background-color: #232323;
  width: 100%;
  height: 100vh;
  text-align: center;
}
.login > div .card {
  max-width: 480px;
  margin: 0 auto;
}
.login .errors {
  padding: 5px 10px;
  margin: 20px auto;
  max-width: 350px;
  border: 1px solid #ff0000;
  background-color: #232323;
}
.login .errors span,
.login .errors .close {
  color: #f1f1f1;
  text-shadow: none;
  opacity: 1;
  line-height: 0.8;
  font-weight: 600;
}
.login #logo {
  width: 200px;
  margin-bottom: 50px;
}
.login .login-card {
  background-color: white;
  max-width: 350px;
  margin: auto;
}
.login .login-card .body {
  padding: 45px 25px 0px;
}
.login .footer {
  width: 100%;
}
.login .button {
  width: 100%;
  height: 55px;
  background-color: #ff0000;
  color: white;
  font-family: 'Acciona Font';
  font-size: 16px;
}
.login .wrapper {
  display: table;
  max-width: 1024px;
  width: 100%;
  height: 100vh;
  padding: 0px 32px;
  margin: auto;
}
.login .wrapper > div {
  display: table-row;
  vertical-align: middle;
}
.login > .wrapper > .body > div {
  display: table-cell;
  vertical-align: middle;
}
.login .wrapper .footer {
  height: 50px;
  text-align: right;
}
.login .wrapper .footer span {
  font-size: 14px;
  color: white;
}
.login .form-row {
  display: table;
  width: 100%;
  min-width: 300px;
  padding: 0px;
  vertical-align: middle;
  margin-bottom: 15px;
}
.login .form-row input[type='text'],
.login .form-row input[type='password'] {
  display: table-cell;
  background: #f1f1f1;
  padding: 11.5px 20px;
  vertical-align: middle;
  width: 100%;
  font-size: 16px;
  color: #666666;
}
.login .form-row input[type='chexbox'] {
  text-align: left;
}
.login .form-row > span {
  font-size: 12px;
  color: black;
}
.login .form-row .cb-container {
  display: block;
  position: relative;
  cursor: pointer;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Style the checkmark/indicator */
}
.login .form-row .cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.login .form-row .cb-container:nth-child(1) {
  padding-left: 25px;
}
.login .form-row .cb-container .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 1px solid black;
}
.login .form-row .cb-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.login .form-row .cb-container input:checked ~ .checkmark {
  background-color: black;
}
.login .form-row .cb-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.login .form-row .cb-container input:checked ~ .checkmark:after {
  display: block;
}
.login .form-row .cb-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.login .form-img {
  display: table-cell;
  background-color: #666666;
  width: 45px;
  height: 45px;
}
.login .form-img .img {
  margin: auto;
}
.login .forgot {
  font-size: 16px;
  color: white;
  margin-top: 18px;
  display: block;
}
.login .forgot:hover {
  color: #f1f1f1;
}
@media (max-width: 550px) {
  .login .wrapper {
    padding: 0px 10px;
  }
  .login .wrapper .footer {
    text-align: center;
  }
  .login .form-row {
    min-width: 250px;
  }
  .login .login-card {
    max-width: 300px;
  }
}
/* ************************ END Login Style ******************* */

/* ************************ START PWA Style ******************* */

.projectsButton {
  background-color: white;
  float: right;
  padding: 5px 10px;
  margin: 22px 0px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.projectsButton:hover,
.projectsButton:hover span {
  background-color: red;
  color: white;
}

.adminButton {
  margin: 22px 0px;
  display: flex;
  align-items: center;
}

.modalActions {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 5px 3px rgba(90, 90, 90, 0.301);
  padding: 10px;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80px;
  min-height: 160px;
}

.modalActions button {
  margin: 5px;
  width: 45px;
  height: 38px;
}

#infoNormative {
  box-shadow: 0px 0px 5px 2px rgb(90 90 90 / 15%);
}

#infoNormative .ant-collapse,
#infoNormative .ant-collapse > .ant-collapse-item {
  border: none;
}

#infoNormative .ant-collapse-header {
  justify-content: center;
  text-align: center;
  padding: 5px 16px !important;
  background-color: red;
  font-weight: bold;
  color: white;
}

#infoNormative .ant-collapse-header span {
  color: white;
}

#infoNormative .ant-collapse-content > .ant-collapse-content-box {
  padding: 8px 0px 0px 7px;
  text-transform: none;
}

#infoNormative .ant-collapse-header .ant-collapse-arrow {
  display: none;
}

.disabled {
  opacity: 35%;
}

.containerModelButtons {
  display: flex;
  flex-direction: column;
}

.containerModelButtons button {
  width: 100%;
  margin: 10px 0px;
  height: 100px;
  font-size: 25px !important;
  font-weight: bold;
  background-color: red;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
}

.containerModelButtons button:active {
  transform: scale(0.98);
}

#normativeEHE_CE {
  max-height: 250px;
  overflow-y: auto;
}

#normativeEHE_CE p {
  position: relative;
  margin: 0px 0px 0px 0px;
}

.secondList p {
  margin-left: 28px !important;
}

.projectsButton:before {
  content: '\f03a';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
}

#consistency {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 45px;
  font-size: 18px;
}

.contentPhotoButton {
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 180px;
}
.photoButton {
  box-shadow: 0px 0px 10px #c8c8c8;
  border-radius: 8px;
  min-width: 200px;
  cursor: pointer;
  transition: 0.5s;
}
.photoButton .iconPhoto {
  font-size: 100px;
  margin-top: 15px;
  transition: 0.5s;
}
.photoButton:active {
  box-shadow: 0px 0px 1px #c8c8c8;
}
.photoButton:active .iconPhoto {
  font-size: 98px;
}
.photoTaken {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border: 1px solid #f1f1f1;
}

.ant-breadcrumb > span:last-child a {
  color: rgba(255, 255, 255, 0.85) !important;
}

.hoursAndMinuteContent {
  display: flex;
  justify-content: center;
}

.optionsTimePicker {
  position: absolute;
  top: 82px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  min-width: 70px;
  max-width: 120px;
  max-height: 200px;
  background-color: #f1f1f1;
  z-index: 999999;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 20%);
  font-size: 17px;
}

.hoursAndMinute {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.hoursAndMinute ul::-webkit-scrollbar {
  display: none;
}

.hoursAndMinute ul {
  overflow: scroll;
  margin: 8px;
  max-height: 100px;
  width: 35px;
}

.hours,
.minutes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hours i:before,
.minutes i:before {
  color: #666 !important;
}

.optionsTimePicker li {
  padding: 5px;
  text-align: center;
}

.optionsTimePicker li:hover,
.timeSelected {
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 20px;
}

/* Table header */

#ButtonHeader {
  width: 100px;
}

/* Filters */

.iconSortAsc,
.iconSortDesc {
  height: 10px;
  width: 10px;
  cursor: 'pointer';
}

.iconSortAsc svg {
  transform: scale(1.1);
}

.iconSortAscSelected svg,
.iconSortDescSelected svg {
  fill: red;
}

.modalFilterableHidden,
.modalSearchableHidden {
  width: 130px;
  max-height: 0px;
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  background-color: white;
  border: 0px solid transparent;
  box-shadow: 0 0 0px 0px #0000001f;
  overflow: hidden;
  visibility: hidden;
  transition: 0.5s;
}

.modalFilterableHidden {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.modalFilterable,
.modalSearchable {
  max-height: 120px;
  box-shadow: 0 0 0px 2px #0000001f;
  visibility: initial;
}

/* ** Start inputDate ** */
.MyDatePicker {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}
.MyDatePicker * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.inputDate.disabled {
  cursor: not-allowed;
}

.mdp-input {
  width: 100%;
  overflow: hidden;
}
.mdp-input input:focus {
  outline: none;
}
.mdp-input input {
  width: 110%;
  background: #f5f5f5;
  border: none;
  padding-right: 10px;
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.mdp-container {
  position: absolute;
  top: 65px;
  width: 360px;
  min-height: 350px;
  background: #f1f1f1;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 25px 30px;
  z-index: 999999;
}
.mdpc-head {
  float: left;
  width: 100%;
  height: 53px;
}
.mdpc-body {
  float: left;
  width: 100%;
  margin-top: 8px;
}

.infoError {
  text-align: right;
  color: red;
  line-height: 14px;
}

/**
* Controls
*/

.mdpch-button {
  float: left;
  width: 45px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.mdpchb-inner:hover > span,
.mdpchb-inner:hover > span::after {
  border-color: white !important;
}
.mdpchb-inner:hover {
  cursor: pointer;
  background: #ff0000;
}
.mdpchb-inner {
  float: left;
  height: 35px;
  width: 35px;
  /* background: #f4f4f4; */
  border-radius: 100%;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
}

.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after,
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows {
  display: block;
  float: left;
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  position: absolute;
}
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows {
  transform: rotate(45deg);
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -4px;
}
.mdpchbi-right-arrows,
.mdpchbi-right-arrow {
  transform: rotate(225deg);
  margin-left: -4px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after {
  content: '';
}

.mdpchbi-left-arrows {
  margin-left: -5px;
}
.mdpchbi-right-arrows {
  margin-left: -2px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after {
  left: 3px;
  top: -5px;
}

.mdpch-container {
  float: left;
  width: 120px;
  height: 100%;
}
.mdpchc-year {
  float: left;
  width: 100%;
  height: 30px;
  font-size: 27px;
  color: #666;
  font-weight: 200px;
  text-align: center;
}
.mdpchc-month {
  float: left;
  width: 100%;
  height: 15px;
  font-size: 13px;
  color: #666;
  font-weight: 200px;
  text-align: center;
}

/**
*  Calendar
*/
.cc-month,
.cc-head,
.cch-name,
.cc-body,
.cdc-day span,
.cdc-day,
.c-day-container,
.c-container {
  position: relative;
  display: block;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.c-container {
  width: 100%;
  height: 100%;
}

.cc-month {
  height: 30px;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
.cc-head {
  height: 30px;
  width: 100%;
  margin-top: 10px;
}
.cch-name {
  width: 14.285%;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  color: #666;
  font-size: 14px;
  text-align: center;
}
.cc-body {
  height: 270px;
  width: 100%;
}
.c-day-container {
  width: 14.285%;
  height: 16.6666%;
}
.cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
}
.cdc-day span {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
}

.cdc-day span:hover {
  cursor: pointer;
  background: #ff0000;
  color: white;
}
.cdc-day span {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  font-weight: 600;
  border-radius: 100%;
  line-height: 30px;
}
.c-day-container.disabled {
  pointer-events: none;
}

.c-day-container.disabled .cdc-day span {
  color: #a5a5a5;
}
.c-day-container.highlight .cdc-day span {
  background: #ffa0a0;
  color: white;
}
.c-day-container.highlight-red .cdc-day span {
  background: #ff0000;
  color: white;
}

/* ** End inputDa ** */

/* PRM */

.listNotePRM {
  margin: 1px;
  padding-right: 7px;
  padding-left: 7px;
  color: rgb(255, 0, 0);
  background: rgb(255, 241, 240);
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: rgb(255, 0, 0);
  font-size: 15px;
}

/* ************************ END PWA Style ******************* */
