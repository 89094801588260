.listItem {
    color: #666666;
}
.title {
    text-align: center;
    margin-bottom: 20px;
}

.extraContent {
    display: flex;
    justify-content: stretch;
    width: 100%;
    padding-top: 20px;

    & > * {
        flex: 1;
        padding: 15px 30px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-radius: 0px;
        min-width: 175px;
    }
}
