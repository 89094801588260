.sensor {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #ececec;

  & > div {
    flex: 1 0;
    margin-bottom: 0px;
  }

  & > div:not(:last-child) {
    margin-right: 10px;
  }

  & > div:last-child {
    position: absolute;
    top: 15px;
    right: 10px;
  }
}
