.contentPhotoButton {
    text-align: center;
    display: flex;
    justify-content: center;
    min-height: 180px;
}
.photoButton {
    // box-shadow: 0px 0px 10px #c8c8c8;
    border-radius: 8px;
    min-width: 200px;
    cursor: pointer;
    transition: 0.5s;
}
.photoButton .iconPhoto {
    font-size: 100px;
    margin-top: 15px;
    transition: 0.5s;
}
.photoButton:active {
    box-shadow: 0px 0px 1px #c8c8c8;
}
.photoButton:active .iconPhoto {
    font-size: 80px;
}

.titlePhoto {
    font-size: 20px;
    margin-top: 10px;
}
