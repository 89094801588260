.onOffButton {
    background-color: #666666;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    &.onState {
        background-color: red;
    }

    &:hover {
        cursor: pointer;
    }
}
