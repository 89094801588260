@mixin text() {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;

  span {
    text-transform: none;
  }
}

.wrapper {
  padding: 15px 20px;
  .header {
    display: flex;
    background-color: #e6e6e650;
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    & > dl:not(:last-child) {
      margin-right: 20px;
    }

    & > dl:last-child {
      margin-left: auto;
    }

    dt {
      @include text;
      margin: 0px;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    dd {
      color: #666;
    }

    //margin-bottom: 40px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
  }

  .tabs {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
  }
}
.spin {
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
