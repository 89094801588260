.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  & > button {
    margin: 0;
  }
}

.wrapper {
  padding: 20px;
  table {
    table-layout: fixed;
    border: 1px solid #f1f1f1;
    input[type='text'] {
      color: black;
    }
    input[type='number'] {
      width: 80px;
      text-align: right;
      margin-right: 10px;
    }
  }
  .addNewRowButton {
    background: #f1f1f1;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
