.wrapper {
  display: flex;
  & > div {
    flex: 0 1 49%;
  }
}

.checkbox {
  label {
    display: block;
    width: 100%;
    font-weight: normal;

    input {
      margin-right: 10px;
    }
  }
}
