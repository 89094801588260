.wrapper {
  position: relative;
  .headerWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 1000;

    & > * {
      min-width: 100%;
      margin-bottom: 10px;
    }

    .icon {
      font-size: 1em;
      color: #666 !important;
      margin-right: 5px !important;
    }

    & > *:last-child {
      display: flex;
    }
  }
  .chartWrapper {
    height: 40vh;
  }
}

