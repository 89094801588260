.uploadZone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 150px;

  & > i {
    font-size: 100px;
    margin-top: 15px;
    transition: 0.5s;
  }
}

.photoPreview {
  display: flex;
  justify-content: center;
  align-items: center;
}
