.wrapper {
  position: relative;
  height: 280px;
  .headerWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 1000;

    .icon {
      font-size: 1em;
      color: #666 !important;
      margin-right: 5px !important;
    }

    & > *:last-child {
      display: flex;
    }
  }

}
