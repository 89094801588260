.tab {
  height: 36px;
  padding: 5px;
  margin: 0px 10px;
  font-size: 14px;
  letter-spacing: normal;
  background-color: red;
  border-radius: 0px;
  color: white;
  min-width: 50px;
  text-transform: uppercase;

  &.noSelected {
    background-color: rgb(230, 230, 230);
    color: #666;
  }

  &.noSelected:hover {
    background-color: rgba(35, 35, 35, 0.733);
    color: white;
  }
}

.header {
  padding: 10px;
  background-color: #e6e6e6;
  text-transform: uppercase;

  & > div:first-child {
    margin: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
}
