.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;

    &.hidden {
        display: none;
    }
    .innerTitle {
        border-bottom: #f1f1f1 1px solid;
        width: 100%;
        padding: 15px 30px;
    }
    .content {
        z-index: 11;
        // background-color: #f1f1f1;
        background-color: #ffffff;
        border: 1px;
        border-radius: 10px;
        height: fit-content;
        position: relative;
    }
    .innerContent {
        padding: 10px;
        //overflow-y: scroll;

        *::-webkit-scrollbar {
            display: none;
        }
    
        * {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }
    .closeIcon {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
}
