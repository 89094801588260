.buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;

    & > button {
        flex: 1;
    }
}
.text {
    text-align: center;
    p {
        white-space: pre-line;
    }
}
