.wrapper {
    .header {
        background-color: #e6e6e6;
        text-transform: uppercase;
        height: 56px;
        margin: 0px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
    }
    .card {
        border-radius: 6px;
        margin-bottom: 15px;
        background-color: white;
        padding: 25px 30px;

        &:first-of-type {
            margin-top: 15px;
        }

        & > p {
            & > * {
                white-space: pre;
            }
            &:last-of-type {
                margin-bottom: 0px;
            }

            & > span:first-child {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;
                display: inline-block;
            }

            & > span:nth-child(2) {
                color: #666666;
            }

            & > span > span {
                display: inline-flex;
                vertical-align: text-bottom;
            }
        }

        .footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }
    }

    .spinnerWrapper {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .noResults {
        text-align: center;
        padding: 20px;
        //background: #00000005;
        background: #ffffff75;
        margin-top: 10px;
    }
}
