.formitem {
    & > div > div:nth-child(1) {
        padding: 0px !important;
    }
    label {
        text-transform: uppercase;
        font-size: 12px !important;
        font-weight: 600;
        color: #000 !important;
        margin: 0px !important;
        font-family: "Acciona Font" !important;
    }
}
