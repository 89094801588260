.select {
  background: rgb(241, 241, 241);
  min-height: 45px;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 16px;
  color: #232323;
  width: 100%;

  &.sm {
    min-height: 25px;
  }
}

.selectOptions {
  box-shadow: 2px 6px 9px 1px rgba(0, 0, 0, 0.22);
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 1001;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #f1f1f1;

  & li:hover {
    background-color: rgb(241, 241, 241);
  }

  & ul {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }

  & li {
    white-space: initial;
    padding: 0px 12px;
    line-height: 44px;
    text-indent: 0px;
  }

  &.sm {
    li {
      line-height: 32px;
    }
  }
}

.selectStyled {
  position: relative;
  min-height: 45px;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  svg {
    visibility: hidden;
    font-size: 12px;
    margin-right: 5px;
    path {
      fill: #abaaaa;
    }
  }

  &:hover svg {
    visibility: visible;
  }

  &::after {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    min-width: 40px;
    padding: 4.5px 8px;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #666;
    border: 7px solid transparent;
  }

  &.isLoading::after {
    content: "\f110";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    min-width: 40px;
    padding: 4.5px 8px;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #666;
    border: 7px solid transparent;
  }
}

.select.sm .selectStyled {
  min-height: 26px;
  &.isLoading::after {
    height: 26px;
  }
}

.isDisabled {
  cursor: not-allowed;
  & > div {
    color: #666666;
    background-color: #abaaaa;
  }
}

.placehold {
  color: #abaaaa;
}
