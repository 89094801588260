.panel {
  & > :nth-child(2) > div {
    padding: 0 !important;
  }
}

.row {
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }

  & > div:nth-child(2) {
    margin-left: 5px;
  }
}
