@mixin text() {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;

  span {
    text-transform: none;
  }
}
.sensors {
  max-height: 50vh;
  overflow-y: auto;
  /* & > div {
    & > div {
      padding: 15px 20px;
      text-align: center;
    }
    & > div:nth-child(1) {
      flex: 0 0 10%;
      min-width: 10%;
    }
    & > div:nth-child(2) {
      flex: 0 0 30%;
    }
    & > div:nth-child(3) {
      flex: 0 0 15%;
    }
    & > div:nth-child(4) {
      flex: 0 0 15%;
    }
    & > div:nth-child(5) {
      flex: 0 0 15%;
    }
    & > div:nth-child(6) {
      flex: 0 0 15%;
    }
  }
  .sensorsHeader {
    display: flex;
    justify-content: stretch;
    & > div {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @include text;
      padding: 5px 20px;
    }
  } */
  & > p {
    @include text;
    margin: 0px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .sensor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    justify-content: center;
    border-radius: 10px;
    background-color: rgba(230, 230, 230, 0.3);
    margin-bottom: 10px;
    padding: 20px 20px;

    & > div {
      display: flex;
      align-items: center;

      &:first-child p {
        border-bottom: 1px #ececec solid;
        padding-bottom: 5px;
      }

      & > * {
        flex: 1;
        font-size: 14px;
      }

      & p.minmaxTemperatureWrapper {
        display: flex;

        & > div {
          color: #666;
        }
      }

      p:first-child {
        @include text;
        flex: 2;
      }
    }
  }
}
