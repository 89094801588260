.calibration {
  position: absolute;
  z-index: 10;
  top: 3px;
  left: 20px;
  white-space: pre;
  span {
    font-weight: bold;
  }
}
