.select {
  background: rgb(241, 241, 241);
}
.form-row .select {
  min-height: 45px;
  height: auto;
}
.form-row .select-options li {
  white-space: initial;
  padding: 12px 16px;
  text-indent: 0px;
}
.form-row .select-styled {
  position: initial;
  min-height: 45px;
  height: auto;
  padding: 0px 40px 0 15px;
  display: flex;
  align-items: center;
}
.form-row .select-styled:after {
  height: 100%;
  display: flex;
  align-items: center;
}
