@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-extralight.eot');
  src:
    url('../fonts/acciona-extralight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-extralight.woff2') format('woff2'),
    url('../fonts/acciona-extralight.woff') format('woff'),
    url('../fonts/acciona-extralight.ttf') format('truetype'),
    url('../fonts/acciona-extralight.svg#accionaextralight') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-light.eot');
  src:
    url('../fonts/acciona-light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-light.woff2') format('woff2'),
    url('../fonts/acciona-light.woff') format('woff'),
    url('../fonts/acciona-light.ttf') format('truetype'),
    url('../fonts/acciona-light.svg#accionalight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'acciona-font';
  src: url('../fonts/acciona-font.eot');
  src:
    url('../fonts/acciona-font.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-font.woff2') format('woff2'),
    url('../fonts/acciona-font.woff') format('woff'),
    url('../fonts/acciona-font.ttf') format('truetype'),
    url('../fonts/acciona-font.svg#acciona-fontfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-regular.eot');
  src:
    url('../fonts/acciona-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-regular.woff2') format('woff2'),
    url('../fonts/acciona-regular.woff') format('woff'),
    url('../fonts/acciona-regular.ttf') format('truetype'),
    url('../fonts/acciona-regular.svg#accionaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Acciona Font';
  src: url('../fonts/acciona-medium.eot');
  src:
    url('../fonts/acciona-medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/acciona-medium.woff2') format('woff2'),
    url('../fonts/acciona-medium.woff') format('woff'),
    url('../fonts/acciona-medium.ttf') format('truetype'),
    url('../fonts/acciona-medium.svg#accionamedium') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'led_dot-matrixregular';
  src: url('../fonts/led_dot-matrix-webfont.eot');
  src:
    url('../fonts/led_dot-matrix-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/led_dot-matrix-webfont.woff2') format('woff2'),
    url('../fonts/led_dot-matrix-webfont.woff') format('woff'),
    url('../fonts/led_dot-matrix-webfont.ttf') format('truetype'),
    url('../fonts/led_dot-matrix-webfont.svg#led_dot-matrixregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  outline: none;
  outline-offset: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: none;
  border: none;
  color: #232323;
}

body {
  font-size: 15px !important;
  background-color: #f1f1f1 !important;
  padding: 0px;
  font-family: 'Acciona Font', sans-serif !important;
  font-weight: normal;
}

a:focus {
  outline: none;
  outline-offset: 0;
}

a:focus,
a:hover {
  color: initial;
  text-decoration: none;
}

a,
a:visited,
a:active {
  color: #232323;
}

a.link {
  text-decoration: underline;
  color: #ff0000;
  font-weight: 600;
}

.btn,
.btn:active,
.btn:focus,
.btn:visited {
  outline: none;
  font-size: 14px !important;
}

img {
  max-width: 100%;
  height: auto;
}

button:active > *,
button:focus > * {
  position: relative;
  top: 0;
  left: 0;
}
/**/
a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

header {
  display: table;
  width: 100%;
  z-index: 500;
  background: white;
  height: 75px;
  border-bottom: 1px solid #f1f1f1;
}

header > div {
  display: table-cell;
  vertical-align: middle;
}

header > :first-child {
  width: 300px;
}

header > :nth-child(2) {
  text-align: center;
}

header > :last-child {
  width: 300px;
  text-align: right;
}

header #logo {
  width: 120px;
  margin: 8px 65px 0 65px;
}

header #logo-guler {
  height: 45px;
  vertical-align: middle;
}

header span {
  margin-left: 40px;
  font-size: 18px;
  vertical-align: middle;
}

header .right-menu {
  display: inline-block;
  margin-right: 35px;
}

header .right-menu a:hover {
  color: #ff0000;
}

header .right-menu li {
  display: inline-block;
}

header .right-menu li a {
  padding: 0px 5px;
}

header .right-menu img {
  float: left;
  margin-left: 20px;
  margin-right: 5px;
  width: 18px;
}

header .right-menu > :first-child:not(:last-child) a {
  display: block;
  border: 1px solid #ff0000;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 5px;
}

header .right-menu > :first-child:not(:last-child) a:before {
  content: '\f03a';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  margin-right: 10px;
}

header .right-menu > :first-child:not(:last-child) a:hover {
  color: white;
  background-color: red;
}

header .right-menu > :first-child:not(:last-child) a:hover:before {
  color: white;
}

header .profile-menu {
  background-color: white;
  position: absolute;
  z-index: 10001;
  right: 20px;
  border: 1px solid #f1f1f1;
  top: 56px;
}

header .profile-menu ul {
  margin-bottom: 0px;
}

header .profile-menu ul li a {
  display: block;
  padding: 10px 20px;
  text-align: left;
  color: #232323;
}
header .profile-menu ul li button {
  display: block;
  padding: 10px 20px;
  text-align: left;
  color: #232323;
}

header .profile-menu ul li button:hover,
header .profile-menu ul li button:hover .icon {
  color: #ff0000;
}
header .profile-menu ul li a:hover,
header .profile-menu ul li a:hover .icon {
  color: #ff0000;
}

header .profile-menu ul li .icon {
  padding-right: 10px;
}

header .projectsButton span {
  margin-left: 10px;
  font-size: 15px;
}

@media (max-width: 991px) {
  #logo-guler {
    display: none;
  }

  header > :first-child {
    width: 30%;
  }

  header > :nth-child(2) {
    text-align: center;
  }

  header > :last-child {
    width: 30%;
    text-align: right;
  }

  header .right-menu > :first-child:not(:last-child) a {
    font-size: 0px;
  }

  header .right-menu > :first-child:not(:last-child) a:before {
    font-size: 15px;
    margin-right: 0px;
  }
}

@media (max-width: 770px) {
  header .projectsButton span {
    display: none;
  }
}

@media (max-width: 550px) {
  header #logo {
    width: 120px;
    margin: 0px 0px 0 15px;
  }

  header .profile-btn {
    font-size: 0px;
  }

  header .right-menu {
    margin-right: 15px;
  }
  .adminButton span {
    display: none;
  }
}

.breadcrumbs {
  background-color: #232323;
  height: 50px;
  text-align: left;
  vertical-align: middle;
}

.breadcrumbs .open-btn {
  height: 50px;
  padding: 17px 25px;
  float: left;
  text-align: center;
  background-color: #ff0000;
  color: white;
  cursor: pointer;
  position: absolute;
}

.breadcrumbs ul.breadcrumb-options {
  margin-left: 85px;
  transition: all 0.3s ease-in-out;
}

.breadcrumbs ul.breadcrumb-options.menu-open {
  padding-left: 185px;
}

.breadcrumbs ul {
  display: inline;
}

.breadcrumbs ul li {
  display: inline-block;
  font-size: 16px;
  position: relative;
  top: 14px;
}

ul.modules {
  float: right;
}

ul.modules a.modal-filters {
  padding: 7px 15px;
  border: 1px solid red;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 15px;
}

ul.modules a.modal-filters:hover {
  background: #ff0000;
  color: white;
}

.breadcrumbs ul li a,
.breadcrumbs span,
.breadcrumbs span a {
  color: white;
  text-transform: uppercase;
}

.breadcrumbs span {
  font-size: 16px;
  font-weight: bold;
}

.breadcrumbs ul li:after {
  content: '>';
  display: inline-block;
  position: relative;
  right: 0;
  color: white;
  padding: 0px 5px;
}

.breadcrumbs ul.modules li:after {
  content: '-';
}

.breadcrumbs ul > :last-child:after {
  display: none;
}

.breadcrumbs ul.separator li {
  margin: 0px 25px;
  color: #f00;
}

.breadcrumbs #tunnel_selected {
  display: block;
  float: right;
}

.breadcrumbs #tunnel_selected button {
  height: 50px;
  background-color: #ff0000;
  border: 1px solid #ff0000;
  padding: 14px;
  color: white;
}

.breadcrumbs #tunnel_selected button:before {
  content: '\f079';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
  display: block;
  margin-right: 15px;
}

.breadcrumbs #tunnel_selected .dropdown-menu {
  border: 0px;
}

.breadcrumbs #tunnel_selected .dropdown-menu li a {
  padding: 10px 55px;
}

.breadcrumbs #tunnel_selected .dropdown-menu li a:hover {
  background-color: #ff0000;
  color: white;
}

.breadcrumbs #tunnel_list {
  z-index: 999;
  margin-left: 0px;
  position: absolute;
  background: white;
  right: 0;
  display: none;
  top: 50px;
  box-shadow: -2px 2px 3px 2px rgba(0, 0, 0, 0.1);
}

.breadcrumbs #tunnel_selected.open #tunnel_list {
  display: block;
}

.breadcrumbs #tunnel_list li {
  display: block;
  position: static;
  top: 0;
}

.breadcrumbs #tunnel_list li:after {
  display: none;
}

.breadcrumbs #tunnel_list li a {
  white-space: nowrap;
  color: #232323;
  display: block;
  padding: 15px 55px;
  position: static;
}

.breadcrumbs #tunnel_list li a:hover {
  background-color: #ff0000;
  color: white;
}

.breadcrumbs #tunnel_list.open {
  display: block;
}

.menu-li-modules {
  display: none;
}

.menu-li-filters {
  display: none;
}

@media (max-width: 1024px) {
  #menu-info-title {
    display: none !important;
  }

  .menu-li-modules {
    display: list-item;
  }

  .breadcrumbs ul li a {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .breadcrumbs .separator {
    display: none !important;
  }

  .breadcrumbs ul > :not(:nth-last-child(2)) {
    display: none;
  }

  .breadcrumbs ul > :nth-last-child(2):after {
    content: '\f053';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 10px;
    float: left;
  }
}

.menu-wrapper {
  position: absolute;
  top: 75px;
  height: calc(100vh - 75px);
  overflow-y: auto;
  z-index: 1000;
  background: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  padding-top: 30px;
  transition: all 0.3s ease-in-out;
}

.menu-wrapper.closed {
  transform: translateX(-270px);
}

.menu-wrapper .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  color: #666666;
  cursor: pointer;
}

.menu-wrapper .close-btn.close-btn-right {
  left: 0;
}

.menu-wrapper .menu li {
  transition: width 0.4s;
  position: relative;
  background-color: white;
  text-transform: uppercase;
}

.menu-wrapper .menu li a:not(.jstree-anchor),
.menu-wrapper .menu li div {
  display: block;
}

.menu-wrapper .menu li a:hover {
  color: #ff0000;
  border-left: 4px solid red;
}

.menu-wrapper .menu.side li {
  width: 250px;
}

.menu-wrapper .menu.side li a {
  padding: 15px 10px;
}

.menu-wrapper .menu.side li a.more:after {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

li.menu-li-filters.nochild a.more:after {
  content: none !important;
}

.menu-wrapper .menu.side li ul {
  position: absolute;
  display: none;
  top: 0;
  right: -250px;
}

.menu-wrapper .menu.side li:hover > ul {
  display: block;
}

.menu-wrapper .menu.side li .icon {
  margin-right: 0px;
}

.menu-wrapper .menu.side > li {
  width: 45px;
}

.menu-wrapper .menu.side > li > a {
  font-size: 0px;
  line-height: 0px;
}

.menu-wrapper .menu.accordion li {
  width: 250px;
}

.menu-wrapper .menu.accordion li a:not(.jstree-anchor) {
  padding: 25px 20px;
}

.menu-wrapper .menu.accordion li a:hover:not(.jstree-anchor) {
  padding-left: 16px;
}

.menu-wrapper .menu.accordion li a.more:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

.menu-wrapper .menu.accordion li.active > a {
  color: #ff0000;
}

.menu-wrapper .menu.accordion li.active > a i {
  color: #ff0000;
}

.menu-wrapper .menu.accordion li.active > a.more:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

.menu-wrapper .menu.accordion li ul:not(.jstree-children):not(.jstree-node) {
  display: none;
}

.menu-wrapper .menu.accordion li ul:not(.jstree-children):not(.jstree-node) li {
  background-color: #f2f2f2;
}

.menu-wrapper
  .menu.accordion
  li
  ul:not(.jstree-children):not(.jstree-node)
  li
  ul
  li {
  background-color: #e6e6e6;
}

.menu-wrapper
  .menu.accordion
  li
  ul:not(.jstree-children):not(.jstree-node)
  li
  ul
  li
  ul
  li {
  background-color: #d9d9d9;
}

.menu-wrapper .menu.accordion li .icon {
  margin-top: -8px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-position: center;
  background-size: cover;
}

.icon.home {
  background-image: url('../img/menu_home.svg');
}

.icon.export {
  background-image: url('../img/menu_export.svg');
}

.icon.config {
  background-image: url('../img/menu_config.svg');
}

.icon.tbm {
  background-image: url('../img/menu_tbm.svg');
}

.icon.map {
  background-image: url('../img/menu_maps.svg');
}

.icon.navigation {
  background-image: url('../img/menu_navigation.svg');
}

.icon.comunication {
  background-image: url('../img/comunication.svg');
}

.icon.access {
  background-image: url('../img/nokas.svg');
}

.icon.presence {
  background-image: url('../img/control_present.svg');
}

.icon.tracking-control {
  background-image: url('../img/tracking_control.svg');
}

.icon.intruder-system {
  background-image: url('../img/menu_intrusion.svg');
}

.icon.asset {
  background-image: url('../img/menu_asset.svg');
}

.icon.intruders {
  background-image: url('../img/menu_intrusion.svg');
}

.icon.panel-icon {
  background-image: url('../img/menu_panel.svg');
}

.icon.air {
  background-image: url('../img/air_quality.svg');
}

.icon.ventilation {
  background-image: url('../img/ventilation_control.svg');
}

.icon.evacuation {
  background-image: url('../img/evacuation.svg');
}

.icon.cctv {
  background-image: url('../img/cctv.svg');
}

.icon.fleet {
  background-image: url('../img/menu_fleet.svg');
}

.icon.reporting {
  background-image: url('../img/menu_reporting.svg');
}

.icon.docs {
  background-image: url('../img/menu_docs.svg');
}

.icon.info {
  background-image: url('../img/menu_info.svg');
}

/*
.icon.module {
    background-image: url('../img/menu_module.svg');
}


.icon.filter {
    background-image: url('../img/menu_filter.svg');
}
*/

.icon.masshaul {
  background-image: url('../img/menu_masshaul.svg');
}

.icon.dashboard {
  background-image: url('../img/menu_dashboard.svg');
}

.icon.workshift {
  background-image: url('../img/menu_workshift.svg');
}

.icon.alerts {
  background-image: url('../img/menu_alert.svg');
}

.icon.data-consulting {
  background-image: url('../img/menu_data.svg');
}

@media (max-width: 770px) {
  .menu-wrapper-left {
    height: 0px;
    left: 0;
    top: 0;
    position: relative;
    padding-top: 0px;
    transition: max-height 0.3s ease-in;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .menu-wrapper-left {
    transform: none;
    height: auto;
  }

  .menu-wrapper-left.closed {
    transform: translateX(0);
    height: 0px;
  }

  .menu-wrapper-left .menu {
    margin: 0px;
  }

  .menu-wrapper-left .menu li {
    background-color: #ff0000;
  }

  .menu-wrapper-left .menu li a:hover {
    border-left: none;
  }

  .menu-wrapper-left .menu.accordion li {
    width: 100%;
    text-align: center;
  }

  .menu-wrapper-left .menu.accordion li .icon {
    display: none;
  }

  .menu-wrapper-left .menu.accordion li.active > a {
    color: white;
    font-weight: 600;
    font-size: 16px;
  }

  .menu-wrapper-left .menu.accordion li a {
    color: #f1f1f1;
    padding: 15px 20px !important;
    border-bottom: 1px solid #e60000;
  }

  .menu-wrapper-left .menu.accordion li a:hover {
    padding-left: 20px;
    color: #232323;
  }

  .menu-wrapper-left .menu.accordion li ul li {
    background-color: #e60000 !important;
  }

  .menu-wrapper-left .menu.accordion li ul li a {
    border-bottom: 1px solid #cc0000;
  }

  .menu-wrapper-left .menu.accordion li ul li ul li {
    background-color: #cc0000;
  }

  .menu-wrapper-left .menu.accordion li ul li ul li a {
    border-bottom: 1px solid #b30000;
  }

  .menu-wrapper-left .menu.accordion li ul li ul li ul li {
    background-color: #b30000;
  }

  .menu-wrapper-left .menu.accordion li ul li ul li ul li a {
    border-bottom: 1px solid #990000;
  }

  .breadcrumbs > ul.menu-open {
    transform: translateX(0px);
  }

  .breadcrumbs ul.breadcrumb-options.menu-open {
    padding-left: 0px;
  }
}

div#loading_wrapper {
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

div#loading_wrapper #loading-spinner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 125px);
  transition: all 0.3s ease-in-out;
  float: right;
}

@media (max-width: 770px) {
  .content {
    overflow: hidden;
    overflow-y: auto;
  }
}

@media (min-width: 1000px) {
  .content.menu-open {
    width: calc(100% - 250px);
  }
}

.content > .wrapper {
  min-height: calc(100vh - 170px);
}

.content > .wrapper.dashboard,
.content > .wrapper.fullmap {
  min-height: calc(100vh - 125px);
}

.content > .wrapper.fullmap {
  max-width: 3000px;
  padding: 0px 15px;
}

.wrapper {
  display: block;
  position: relative;
  width: 100%;
  max-width: 1024px;
  padding: 20px 32px;
  margin: auto;
}

.wrapper.fullscreen {
  max-width: 3000px;
}

.wrapper.dashboard {
  max-width: 1366px;
}

.footer {
  width: 100%;
  background-color: #232323;
  height: 45px;
  z-index: 99999999999999;
  padding-top: 10px;
  position: fixed;
  bottom: 0;
}

.footer .wrapper {
  height: 100%;
  display: inline-block;
  padding: 10px;
}

.footer .wrapper span {
  display: inline-block;
  text-align: right;
  vertical-align: middle;
  color: white;
}

.home-button {
  padding: 20px 0px 5px;
  display: table;
  width: 100%;
}

.home-button a {
  display: table-cell;
  padding: 25px;
  border-radius: 10px;
  background-color: white;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.05);
  height: 190px;
}

.home-button a span {
  font-size: 20px;
}

.home-button a .image-btn {
  margin: auto;
  width: 80px;
  height: 80px;
  background-image: url('../img/menu_maps.svg');
  background-size: 80px 80px;
  background-repeat: no-repeat;
}

.home-button a:hover {
  background-color: #ff0000;
}

.home-button a:hover span {
  color: white;
}

.home-button.red a {
  background-color: #ff0000;
}

.home-button.red a:hover {
  background-color: #232323;
}

.home-button.tbm a .image-btn {
  background-image: url('../img/menu_tbm.svg');
}

.home-button.tbm a:hover .image-btn {
  background-image: url('../img/menu_tbm_white.svg');
}

.home-button.workshift a .image-btn {
  background-image: url('../img/menu_workshift.svg');
}

.home-button.workshift a:hover .image-btn {
  background-image: url('../img/menu_workshift_white.svg');
}

.home-button.admin a .image-btn {
  background-image: url('../img/menu_admin_white.svg');
}

.home-button.admin a:hover .image-btn {
  background-image: url('../img/menu_admin.svg');
}

.home-button.chart-builder a .image-btn {
  background-image: url('../img/menu_data.svg');
}

.home-button.chart-builder a:hover .image-btn {
  background-image: url('../img/menu_data_white.svg');
}

.home-button.intrusion a .image-btn {
  background-image: url('../img/menu_intrusion.svg');
}

.home-button.intrusion a:hover .image-btn {
  background-image: url('../img/menu_intrusion_white.svg');
}

.home-button.mapping a .image-btn {
  background-image: url('../img/menu_maps.svg');
}

.home-button.mapping a:hover .image-btn {
  background-image: url('../img/menu_maps_white.svg');
}

.home-button.navigation a .image-btn {
  background-image: url('../img/menu_navigation.svg');
}

.home-button.navigation a:hover .image-btn {
  background-image: url('../img/menu_navigation_white.svg');
}

.home-button.tracking-control a .image-btn {
  background-image: url('../img/tracking_control.svg');
}

.home-button.tracking-control a:hover .image-btn {
  background-image: url('../img/tracking_control_white.svg');
}

.home-button.asset a .image-btn {
  background-image: url('../img/menu_asset.svg');
}

.home-button.fleet a .image-btn {
  background-image: url('../img/menu_fleet.svg');
}

.home-button.fleet a:hover .image-btn {
  background-image: url('../img/menu_fleet_white.svg');
}

.home-button.asset a:hover .image-btn {
  background-image: url('../img/menu_asset_white.svg');
}

.home-button.reporting a .image-btn {
  background-image: url('../img/menu_reporting.svg');
}

.home-button.reporting a:hover .image-btn {
  background-image: url('../img/menu_reporting_white.svg');
}

.home-button.btn-panel a .image-btn {
  background-image: url('../img/menu_panel.svg');
}

.home-button.btn-panel a:hover .image-btn {
  background-image: url('../img/menu_panel_white.svg');
}

.home-button.nokas a .image-btn {
  background-image: url('../img/nokas.svg');
}

.home-button.nokas a:hover .image-btn {
  background-image: url('../img/nokas_white.svg');
}

.home-button.docs a .image-btn {
  background-image: url('../img/menu_docs.svg');
}

.home-button.docs a:hover .image-btn {
  background-image: url('../img/menu_docs_white.svg');
}

.home-button.config a .image-btn {
  background-image: url('../img/menu_config_white.svg');
}

.home-button.config a:hover .image-btn {
  background-image: url('../img/menu_config.svg');
}

.home-button.charts a .image-btn {
  background-image: url('../img/menu_graph.svg');
}

.home-button.charts a:hover .image-btn {
  background-image: url('../img/menu_graph_white.svg');
}

.home-button.sensor a .image-btn {
  background-image: url('../img/menu_sensor.svg');
}

.home-button.sensor a:hover .image-btn {
  background-image: url('../img/menu_sensor_white.svg');
}

.home-button.tracking a .image-btn {
  background-image: url('../img/menu_tracking.svg');
}

.home-button.tracking a:hover .image-btn {
  background-image: url('../img/menu_tracking_white.svg');
}

.home-button.production a .image-btn {
  background-image: url('../img/menu_production.svg');
}

.home-button.production a:hover .image-btn {
  background-image: url('../img/menu_production_white.svg');
}

.home-button.security a .image-btn {
  background-image: url('../img/menu_security.svg');
}

.home-button.security a:hover .image-btn {
  background-image: url('../img/menu_security_white.svg');
}

.home-button.post-processing a .image-btn {
  background-image: url('../img/menu_config.svg');
}

.home-button.post-processing a:hover .image-btn {
  background-image: url('../img/menu_config_white.svg');
}

.home-button.company a .image-btn {
  background-image: url('../img/menu_company.svg');
}

.home-button.company a:hover .image-btn {
  background-image: url('../img/menu_company_white.svg');
}

.home-button.language a .image-btn {
  background-image: url('../img/menu_language.svg');
}

.home-button.language a:hover .image-btn {
  background-image: url('../img/menu_language_white.svg');
}

.home-button.equipment a .image-btn {
  background-image: url('../img/equipment_icon.svg');
}

.home-button.equipment a:hover .image-btn {
  background-image: url('../img/equipment_icon_white.svg');
}

.home-button.user a .image-btn {
  background-image: url('../img/menu_user.svg');
}

.home-button.user a:hover .image-btn {
  background-image: url('../img/menu_user_white.svg');
}

.home-button.layer a .image-btn {
  background-image: url('../img/menu_layer.svg');
}

.home-button.layer a:hover .image-btn {
  background-image: url('../img/menu_layer_white.svg');
}

.home-button.web a .image-btn {
  background-image: url('../img/menu_web.svg');
}

.home-button.web a:hover .image-btn {
  background-image: url('../img/menu_web_white.svg');
}

.home-button.texts a .image-btn {
  background-image: url('../img/menu_text.svg');
}

.home-button.texts a:hover .image-btn {
  background-image: url('../img/menu_text_white.svg');
}

.home-button.stops a .image-btn {
  background-image: url('../img/menu_stops.svg');
}

.home-button.stops a:hover .image-btn {
  background-image: url('../img/menu_stops_white.svg');
}

.home-button.folder a .image-btn {
  background-image: url('../img/menu_folder.svg');
}

.home-button.folder a:hover .image-btn {
  background-image: url('../img/menu_folder_white.svg');
}

.home-button.dashboard a .image-btn {
  background-image: url('../img/menu_dashboard.svg');
}

.home-button.dashboard a:hover .image-btn {
  background-image: url('../img/menu_dashboard_white.svg');
}

.home-button.masshaul a .image-btn {
  background-image: url('../img/menu_masshaul.svg');
}

.home-button.masshaul a:hover .image-btn {
  background-image: url('../img/menu_masshaul_white.svg');
}

.home-button.alerts a .image-btn {
  background-image: url('../img/menu_alert.svg');
}

.home-button.alerts a:hover .image-btn {
  background-image: url('../img/menu_alert_white.svg');
}

.home-button.data a .image-btn {
  background-image: url('../img/menu_data.svg');
}

.home-button.data a:hover .image-btn {
  background-image: url('../img/menu_data_white.svg');
}

.home-button.crew a .image-btn {
  background-image: url('../img/crews_icon.svg');
}

.home-button.crew a:hover .image-btn {
  background-image: url('../img/crews_icon_white.svg');
}

.home-button.section a .image-btn {
  background-image: url('../img/sections_icon.svg');
}

.home-button.section a:hover .image-btn {
  background-image: url('../img/sections_icon_white.svg');
}

.home-button.alert_log a .image-btn {
  background-image: url('../img/menu_alert_log.svg');
}

.home-button.alert_log a:hover .image-btn {
  background-image: url('../img/menu_alert_log_white.svg');
}

.home-button.alert_config a .image-btn {
  background-image: url('../img/menu_alert_config.svg');
}

.home-button.alert_config a:hover .image-btn {
  background-image: url('../img/menu_alert_config_white.svg');
}

.home-button.km a .image-btn {
  background-image: url('../img/menu_km.svg');
}

.home-button.km a:hover .image-btn {
  background-image: url('../img/menu_km_white.svg');
}

.home-button.performance a .image-btn {
  background-image: url('../img/menu_performance.svg');
}

.home-button.performance a:hover .image-btn {
  background-image: url('../img/menu_performance_white.svg');
}

.home-button.volume a .image-btn {
  background-image: url('../img/menu_volume.svg');
}

.home-button.volume a:hover .image-btn {
  background-image: url('../img/menu_volume_white.svg');
}

.home-button.ventilation a .image-btn {
  background-image: url('../img/ventilation_control.svg');
}

.home-button.ventilation a:hover .image-btn {
  background-image: url('../img/ventilation_control_white.svg');
}

.home-button.cctv a .image-btn {
  background-image: url('../img/cctv.svg');
}

.home-button.cctv a:hover .image-btn {
  background-image: url('../img/cctv_white.svg');
}

.home-button.convergence a .image-btn {
  background-image: url('../img/convergence.svg');
}

.home-button.convergence a:hover .image-btn {
  background-image: url('../img/convergence_white.svg');
}

.home-button.backup a .image-btn {
  background-image: url('../img/backup.svg');
}

.home-button.backup a:hover .image-btn {
  background-image: url('../img/backup_white.svg');
}

.home-button.presence a .image-btn {
  background-image: url('../img/control_present.svg');
}

.home-button.presence a:hover .image-btn {
  background-image: url('../img/control_present_white.svg');
}

.home-button.access a .image-btn {
  background-image: url('../img/access_control.svg');
}

.home-button.access a:hover .image-btn {
  background-image: url('../img/access_control_white.svg');
}

.home-button.comunication a .image-btn {
  background-image: url('../img/comunication.svg');
}

.home-button.comunication a:hover .image-btn {
  background-image: url('../img/comunication_white.svg');
}

.home-button.evacuation a .image-btn {
  background-image: url('../img/evacuation.svg');
}

.home-button.evacuation a:hover .image-btn {
  background-image: url('../img/evacuation_white.svg');
}

.home-button.air-quality a .image-btn {
  background-image: url('../img/air_quality.svg');
}

.home-button.air-quality a:hover .image-btn {
  background-image: url('../img/air_quality_white.svg');
}

.home-button.red a {
  background-color: #ff0000;
}

.home-button.red a span {
  color: white;
}

.home-welcome {
  margin-top: 20px;
}

.home-welcome > :first-child {
  font-size: 30px;
}

.home-welcome > :last-child {
  font-size: 18px;
  color: #666666;
}

hr {
  margin: 10px 0px 5px;
  height: 1px;
  color: #ff0000;
  background-color: #666666;
  border: 0px;
}

.card {
  background-color: white;
  min-height: 100px;
  border-radius: 2px;
  margin: 10px 0px;
}

.card .card-header,
.title-section-header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 22px;
  background-color: #e6e6e6;
  height: 38px;
}

.card .card-header select {
  text-transform: uppercase;
}

.card .card-header select option:checked {
  box-shadow: 0 0 10px 100px #ff0000 inset;
}

.title-section-header {
  display: block;
  width: 100%;
}

.card.tab-card .card-header {
  padding: 0px !important;
}

.card.tab-card .card-header ul {
  margin: 0px;
}

.card.tab-card .card-header ul li {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 11px 25px;
}

.card.tab-card .card-header ul li.selected {
  color: #ff0000;
}

.card.tab-card .card-header ul li.selected:before {
  content: '';
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 8px solid transparent;
  border-bottom: 8px solid white;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.card .card-body {
  position: relative;
  padding: 25px 20px;
  overflow: hidden;
}

.ppal-launcher {
  margin-top: 30px;
}

.ppal-launcher > div {
  text-align: center;
  padding: 20px;
}

.ppal-launcher > div > div {
  border-right: 1px solid #f1f1f1;
}

.ppal-launcher > div > :last-child {
  border: none;
}

.ppal-launcher > div img {
  width: 40px;
}

.ppal-launcher > div .measure-title {
  display: block;
  color: #666666;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 20px;
}

.ppal-launcher > div .measure {
  display: block;
  font-size: 36px;
  font-weight: 600;
  color: #232323;
  margin-top: -15px;
}

.map {
  padding: 2px;
}

@media (max-width: 550px) {
  .wrapper {
    width: 100%;
    max-width: 1024px;
    padding: 10px 10px;
    margin: auto;
  }

  .ppal-launcher {
    margin-top: 15px;
  }

  .card.tab-card .card-header ul li {
    padding: 7px 15px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 49%;
    text-align: center;
  }
}

table {
  width: 100%;
  margin-bottom: 0px !important;
}

table thead {
  font-weight: 600;
  text-transform: uppercase;
}

table thead th {
  padding: 15px 20px;
  font-size: 14px;
  border-bottom: 2px solid #f1f1f1;
  border-right: 2px solid #f1f1f1;
}

table.large-table thead th,
table.large-table tbody td {
  padding: 10px 10px;
  text-align: center;
}

table thead th :last-child {
  border-right: none;
}

table thead th {
  text-align: center;
}

table thead th,
table tbody td {
  border-bottom: 2px solid #f1f1f1;
  border-right: 2px solid #f1f1f1;
  color: #666666;
  text-align: center;
}

table tbody td span,
table tbody td a,
table thead th span,
table thead th a {
  color: #666666;
  font-size: 14px;
}

table tbody .failure {
  background-color: #ff0000;
  color: white;
  padding: 2px 5px;
  text-transform: uppercase;
}

table tbody .maintenance {
  background-color: #f7931e;
  color: white;
  padding: 2px 5px;
  text-transform: uppercase;
}

table.table-white th {
  background-color: #e6e6e6;
}

table.table-white td {
  background-color: white;
  position: relative;
}

table.table-white td a {
  display: table-cell;
  width: 100%;
  height: 100%;
}

table.table-white td img {
  width: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.chart-wrapper {
  position: relative;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.chart-wrapper span#percent-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  font-size: 28px;
  font-family: 'Acciona Font';
  font-weight: 600;
  color: #232323;
  transform: translateX(-50%) translateY(-50%);
}

span#file-data {
  display: block;
  border-top: 1px solid #f1f1f1;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  font-size: 21px;
  font-weight: 600;
  text-align: center;
}

.side-menu li a {
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  padding: 25px 10px;
  border-bottom: 1px solid #666666;
}

.side-menu li a:hover {
  color: #ff0000;
}

.side-menu :last-child a {
  border: none;
}

@media (max-width: 550px) {
  .chart-wrapper {
    max-width: 250px;
  }
}

.title-page {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
  font-size: 28px;
}

.form-row {
  display: table;
  position: relative;
  width: 100%;
  padding: 0px;
  vertical-align: middle;
  margin-bottom: 20px;
}

.form-row.row-bottom {
  margin-bottom: 0px;
}

.form-row.input-user:after {
  background: #666666 url(../img/icon_user.svg);
}

/*
    .form-row.input-calendar:after {
        background: #666666 url(../img/icon_calendar.svg);
    }
    */

.form-row.inp .form-row.input-user:after {
  background: #666666 url(../img/icon_user.svg);
}

ut-pass:after {
  background: #666666 url(../img/icon_pass_white.svg);
}

/*
.form-row.input-mail:after {
    background: #666666 url(../img/icon_mail.svg);
}


.form-row.input-phone:after {
    background: #666666 url(../img/icon_phone.svg);
}
*/

.form-row.input-user:after,
.form-row.input-calendar:after,
.form-row.input-pass:after,
.form-row.input-mail:after,
.form-row.input-phone:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 45px;
  background-position: center;
  background-size: 45px;
}

.form-row input[type='text'],
.form-row input[type='password'],
.form-row input[type='number'] {
  display: table-cell;
  position: relative;
  background: white;
  padding: 11.5px 20px;
  vertical-align: middle;
  width: 100%;
  font-size: 16px;
  color: #666666;
}

.div-search {
  position: sticky;
  top: 0;
  padding: 5px 10px;
  background-color: white;
  border-bottom: 1px solid #f1f1f1;
}

input.select-search {
  width: 100%;
  display: block;
  height: 40px;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 15px;
}

.form-row input.error {
  outline: 1px solid #ff0000;
}

.form-row input[type='chexbox'] {
  text-align: left;
}

.form-row > span {
  font-size: 12px;
  color: black;
}

.form-row .cb-container {
  display: block;
  position: relative;
  cursor: pointer;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Style the checkmark/indicator */
}

.form-row .cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form-row .cb-container:nth-child(1) {
  padding-left: 25px;
}

.form-row .cb-container .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 1px solid black;
}

.form-row .cb-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.form-row .cb-container input:checked ~ .checkmark {
  background-color: black;
}

.form-row .cb-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.form-row .cb-container input:checked ~ .checkmark:after {
  display: block;
}

.form-row .cb-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-row .form-img {
  display: table-cell;
  background-color: #666666;
  width: 45px;
  height: 45px;
  vertical-align: top;
}

.form-row .form-img .img {
  margin: auto;
}

.form-row .forgot {
  font-size: 16px;
  color: white;
  margin-top: 18px;
  display: block;
}

.form-row .forgot:hover {
  color: #f1f1f1;
}

.form-row .select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.form-row .select {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 16px;
  color: #232323;
  width: 100%;
  height: 45px;
}

.form-row .select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  padding: 11px 15px;
  transform: transition(all 0.2s ease-in);
}

.form-row .select-styled.placeholder {
  font-style: italic;
  color: #666666;
}

.form-row .select-styled:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  padding: 4.5px 8px;
  min-width: 40px;
  color: white;
  border: 7px solid transparent;
  position: absolute;
  font-style: normal;
  display: block;
  top: 0;
  right: 0;
  background-color: #666666;
}

.form-row .select-styled:hover {
  background-color: #fafafa;
}

.form-row .select-styled:hover:after {
  background-color: #ff0000;
}

.form-row .select-styled:active,
.form-row .select-styled.active {
  background-color: white;
}

.form-row .select-styled:active:after,
.form-row .select-styled.active:after {
  content: '\f106';
}

.form-row .select-styled.disabled {
  font-style: italic;
}

.form-row .select-styled.disabled:after {
  font-style: normal;
  background-color: #e6e6e6;
  color: #ffffff;
}

.form-row .select-styled.disabled:hover {
  cursor: not-allowed;
}

.form-row .select-styled.disabled:hover:after {
  background-color: #e6e6e6;
}

.form-row .select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #f1f1f1;
}

.form-row .select-options li {
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  transform: transition(all 0.15s ease-in);
}

.form-row .select-options li.disabled {
  font-style: italic;
  color: #aaaaaa;
}

.form-row .select-options li.disabled:hover {
  cursor: default;
}

.form-row .select-options li:not(.disabled):hover {
  color: white;
  background: #ff0000;
}

.form-row .select-options li[rel='hide'] {
  display: none;
}

.form-row.input-gray textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  resize: none;
}

.form-row.input-gray input,
.form-row.input-gray textarea,
.form-row.input-gray .select-styled {
  background: #f1f1f1;
}

.form-row.input-gray input:disabled,
.form-row.input-gray .select-styled:disabled {
  background-color: #c2c2c2;
  color: 666;
}

.form-row .btn {
  padding: 15px 30px;
  background-color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0px 5px;
  border-radius: 0px;
}

.form-row .btn.btn-indicator:after {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
  color: #ff0000;
  margin-left: 10px;
}

.form-row .btn .icon {
  color: #ff0000;
  margin-right: 20px;
}

.form-row .btn.btn-red,
.btn.btn-red {
  background-color: red;
  color: white;
}

.form-row .btn.btn-gray {
  background-color: #f1f1f1;
}

.form-row .btn.btn-darkgray {
  background-color: #666666;
  color: white;
}

.ui-button {
  padding: 10px 20px;
  margin-right: 5px;
}

.ui-checkboxradio-radio-label .ui-icon,
.ui-checkboxradio-radio-label:hover .ui-icon {
  display: none;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 1px solid white;
  background: white;
  font-weight: normal;
  color: #666;
  text-align: center;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  border: 1px solid #666;
  background: #666;
  font-weight: normal;
  color: white;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #ff0000;
  background: #ff0000;
  font-weight: normal;
  color: #ffffff;
  box-shadow: none;
}

.ui-widget-header {
  border: 0px;
  background-color: white;
}

.top-25 {
  margin-top: 25px;
}

.btn.warning {
  background-color: #ff0000;
  color: white;
}

.btn.warning:hover {
  background-color: rgb(146, 6, 6);
}

.btn.test {
  background-color: #333333;
  color: white;
}

.btn.test.stop {
  background-color: orange;
  color: white;
}

.btn.warning.stop {
  background-color: #8cc63f;
  color: white;
}

.btn.warning:before,
.btn.test:before,
.btn.panel:before {
  content: '';
  font-family: 'Font Awesome 5 Free';
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  float: left;
}

.btn.warning:before {
  content: '\f071';
  font-weight: 600;
}

.btn.test:before {
  content: '\f013';
  font-weight: 600;
}

.btn.panel:before {
  content: '\f129';
  font-weight: 700;
}

.btn.btn-darkgray {
  background-color: #666666;
  color: white;
}

.btn.btn-red::after,
.btn.btn-darkgray::after {
  color: white;
}

.btn.btn-red .icon,
.btn.btn-darkgray i {
  color: white;
}

.btn:hover,
.btn:hover.btn-gray,
.btn:hover.btn-darkgray {
  background-color: #ff0000;
}

.btn:hover.btn-indicator:after,
.btn:hover.btn-gray.btn-indicator:after,
.btn:hover i,
.btn:hover.btn-gray i,
.btn:hover,
.btn:hover.btn-gray {
  color: white !important;
}

.btn:hover.btn-red {
  background-color: #666666;
}

.form-row .btn.btn-small-edit,
.form-row .btn.btn-small-delete,
.form-row .btn.btn-small-save,
.form-row .btn.btn-small-undo {
  padding: 0px;
}

.form-row .btn.btn-small-edit:after,
.form-row .btn.btn-small-delete:after,
.form-row .btn.btn-small-save:after,
.form-row .btn.btn-small-undo:after {
  content: '';
  font-family: 'Font Awesome 5 Free';
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  right: 0;
}

.form-row .btn.btn-small-edit:after {
  content: '\f044';
}

.form-row .btn.btn-small-delete:after {
  content: '\f2ed';
}

.form-row .btn.btn-small-save:after {
  content: '\f0c7';
}

.form-row .btn.btn-small-undo:after {
  content: '\f0e2';
  font-weight: 900;
}

.form-row.text-label label,
.title-section {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  margin: 0px;
}

.form-row.text-label p {
  color: #666666;
  font-size: 16px;
}

.form-row.text-label .big-content {
  font-size: 36px;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

@media (min-width: 991px) {
  .float-right-sd {
    float: right;
    max-width: 300px;
  }

  .form-row {
    display: table;
    width: 100%;
  }

  .form-row.align-right-sd {
    text-align: right;
  }

  .top-right {
    display: block;
    min-width: 0px;
    width: fit-content;
    position: absolute;
    top: 25px;
    right: 20px;
  }
}

@media (max-width: 991px) {
  .form-row:not(.no-table) a,
  .form-row:not(.no-table) button {
    text-align: center;
    display: inline-block;
    width: auto;
  }

  .form-row.no-table {
    width: 100%;
  }

  .form-row .btn.btn-small-edit,
  .form-row .btn.btn-small-delete,
  .form-row .btn.btn-small-undo,
  .form-row .btn.btn-small-save {
    display: inline-block;
    width: initial;
  }

  .form-row.top-right {
    margin-top: 15px;
    text-align: right;
  }

  .text-label p {
    font-size: 14px;
  }

  .text-label .big-content {
    font-size: 22px !important;
  }
}

@media (max-width: 550px) {
  .form-row {
    display: block;
    min-width: 0px;
  }

  .form-row .btn {
    display: block;
    margin-bottom: 5px;
    width: 100%;
  }
}

@media (max-width: 770px) {
  .title-page {
    padding-bottom: 10px;
    font-size: 20px;
  }
}

.progress {
  background-color: white;
  border-radius: 0px;
  height: 29px;
}

.progress > span,
.progress > div,
.progress > span,
.total-progress > div,
.progress > span,
.total-efficiency > div,
.progress-tunnel-percent > span {
  animation: loading 0.8s 0.3s ease-out;
  transform-origin: left;
  animation-fill-mode: backwards;
}

@keyframes loading {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff0000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff0000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  width: 58px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-switch input[type='checkbox'] {
  margin: 10px 0 0;
  width: 60px;
}
/* INPUT FILE */
input[type='file']#myfile,
input[type='file']#utefile,
input[type='file'].inputfile {
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

label[for='myfile'],
label[for='utefile'],
label.inputfile {
  position: relative;
  font-size: 14px;
  height: 45px;
  color: #666666;
  text-align: left;
  width: 100%;
  background-color: #fff;
  display: block;
  transition: all 0.5s;
  cursor: pointer;
  padding: 13px 60px 15px 20px;
}

label[for='myfile'].file-gray,
label[for='utefile'].file-gray,
label.inputfile.file-gray {
  background-color: #f1f1f1;
}

label[for='myfile'] :after,
label[for='utefile'] :after,
label.inputfile :after {
  content: '';
  position: absolute;
  display: block;
  color: white;
  width: 40px;
  height: 45px;
  font-size: 14px;
  padding: 12px;
  background: #666666 url(../img/icon_upload.svg);
  background-position: center;
  background-size: 45px;
  top: 0;
  right: 0;
}

label[for='myfile']:hover :after,
label[for='utefile']:hover :after,
label.inputfile:hover :after {
  background-color: #ff0000;
}

.backbone .slider.round {
  border-radius: 15px;
  width: 38px;
}

.backbone label.slider-switch {
  margin-bottom: 0px;
  font-weight: 700;
  width: 50px;
  height: 12px;
}

.backbone .slider-switch input[type='checkbox'] {
  margin: 5px 0 0;
  width: 60px;
  margin: 0;
}

.backbone .slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 3px;
  top: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.backbone input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(16px);
}

img.ute-image {
  max-height: 40px;
}

#spinner {
  text-align: center;
  width: 100%;
  vertical-align: middle;
  background-color: rgba(35, 35, 35, 0.2);
  position: absolute;
  z-index: 500;
  height: calc(100vh - 125px);
}

#spinner img {
  transform: translateY(30vh);
}

.row-project,
.row-polygons {
  background-color: #fff;
}

.project-wrapper {
  height: 285px;
}

.modal-content {
  border-radius: 0px;
  border: none;
  box-shadow: none;
}

.modal-footer.form-row {
  padding: 10px;
}

.menu-wrapper-right {
  right: 0px;
}

.menu-wrapper-right.closed {
  transform: translateX(270px);
}

.card-body span {
  display: block;
  font-size: 12px;
}

.card-body span.no-data {
  font-size: 16px;
  font-style: italic;
}

.open-btn-left {
  left: 0px;
}

.open-btn-right {
  right: 0px;
}

.jstree-default .jstree-clicked,
.jstree-hovered {
  background: none !important;
  border-left: none !important;
  box-shadow: none !important;
}

.jstree-default .jstree-clicked {
  color: #f00 !important;
}

#jstree-icon-poly .jstree-icon.jstree-themeicon {
  background-position: 0px 0px !important;
  /*background-image: url('../img/jstree_poly.png');*/
  background-size: 24px auto !important;
  background-position: center !important;
}

#jstree-icon-equipments .jstree-icon.jstree-themeicon {
  background-position: 0px 0px !important;
  /*background-image: url('../img/jstree_equipments.png');*/
  background-size: 24px auto !important;
  background-position: center !important;
}

#jstree-icon-alerts .jstree-icon.jstree-themeicon {
  background-position: 0px 0px !important;
  /*background-image: url('../img/jstree_alarms.png');*/
  background-size: 24px auto !important;
  background-position: center !important;
}

.jstree-container-ul {
  background: none;
}

.jstree-container-ul li {
  background-color: transparent !important;
}

.jstree-default .jstree-clicked {
  color: #232323 !important;
}

a.link-btn-red,
span.link-btn-red {
  padding: 10px;
  background-color: #f00;
  color: #fff;
}

a.link-btn-red:hover,
span.link-btn-red:hover {
  background-color: #666;
}

.gly-spin {
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.xdsoft_datetimepicker {
  width: 310px;
}

.menu-info-err {
  -webkit-box-shadow: inset 0px 0px 0px 2px #f00;
  -moz-box-shadow: inset 0px 0px 0px 2px #f00;
  box-shadow: inset 0px 0px 0px 2px #f00;
}

.gmnoscreen div {
  text-align: center !important;
}

a[rel='noopener'] {
  display: none !important;
}

.row-filter {
  margin: 0px;
  margin-bottom: 15px;
  background: #f00;
  padding-top: 5px;
}

.cell-filter label {
  color: #fff;
}

.cell-filter.cell-head {
  box-shadow: -5px -10px 0 #f00;
}

.align-input {
  display: inline-block;
  vertical-align: middle;
}

.align-label {
  margin-left: 5px;
  vertical-align: sub;
}

.mapboxgl-marker:hover {
  cursor: pointer !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  position: fixed;
  right: 0px;
  bottom: 65px;
}

#map-preview .mapboxgl-canvas-container .mapboxgl-canvas {
  top: 0px;
  left: 0px;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  bottom: 0px;
  right: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  border-radius: 3px 3px 12px 3px;
}

.mapboxgl-compact a {
  margin: 0px !important;
  padding: 0px !important;
  display: inline-block !important;
  width: 100%;
  border: 0px solid #fff !important;
}

.btn-red i.fas {
  color: #fff;
}

.mapboxgl-ctrl-top-right {
  top: 130px !important;
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-clockwise,
.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-anticlockwise {
  width: 20px;
  height: 20px;
  margin: 5px;
  display: inline-block;
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-clockwise {
  background-image: url('../img/arrow-cw.svg');
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-anticlockwise {
  background-image: url('../img/arrow-anticw.svg');
}

#input-filter-date {
  background-color: #fff;
}

#input-filter-date::placeholder {
  color: #232323 !important;
}

#input-filter-date::-webkit-placeholder {
  color: #232323 !important;
}

#input-filter-date::-moz-placeholder {
  color: #232323 !important;
}

#input-filter-date::-ms-input-placeholder {
  color: #232323 !important;
}

.row-project > div > .project-wrapper {
  background-color: white;
  display: block;
  padding: 20px;
  border-radius: 5px;
}

.select-opt-timezones {
  overflow-y: auto;
  max-height: 160px;
}

.input-err {
  outline: 1px solid #ff0000;
}

.colorpicker-alpha {
  display: none !important;
}

.colorpicker {
  min-width: 128px !important;
}

.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  opacity: 0.3 !important;
}

/* Dashboard */
.wrapper.dashboard {
  padding: 0px 15px;
  min-height: calc(100vh - 125px);
}

.card.card-dashboard,
.card.card-dashboard-volumes,
.card.card-map {
  height: calc((100vh - 170px) / 2);
  overflow-y: hidden;
  position: relative;
}

.card.card-dashboard .card-header {
  padding: 6px 22px;
  height: 30px;
}

.card.card-dashboard .efficiency {
  margin: 0px;
  padding: 20px 0px;
}

.card.card-dashboard .efficiency > * {
  text-align: center;
  padding: 15px;
}

.card .card-body.production .chart-wrapper {
  padding: 10px;
  margin-right: 50px;
}

.card .card-wrapper-table {
  padding: 10px 20px;
  display: table;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  vertical-align: bottom;
}

.card .card-wrapper-table .total-progress,
.card .card-wrapper-table .total-efficiency {
  position: relative;
  margin-top: 0px;
  height: 45px;
  background-color: #f1f1f1;
  border-radius: 5px;
  overflow: hidden;
}

.card .card-wrapper-table .total-progress span.metter-ratio,
.card .card-wrapper-table .total-efficiency span.metter-ratio {
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
}

.card .card-wrapper-table .total-progress span,
.card .card-wrapper-table .total-progress div,
.card .card-wrapper-table .total-efficiency span,
.card .card-wrapper-table .total-efficiency div {
  height: 45px;
  line-height: 42px;
}

.card .card-wrapper-table .total-progress span.percent-text {
  color: white;
  text-shadow: 0px 0px 10px black;
}

.card .card-wrapper-table .total-efficiency span.percent-text {
  color: white;
}

.card .card-wrapper-table .total-progress span.metter-ratio,
.card .card-wrapper-table .total-efficiency span.metter-ratio {
  font-size: 15px;
  padding: 0px 10px 0px 0px;
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
}

.card .card-wrapper-table > * {
  display: table-cell;
  padding: 0px 5px;
  text-align: center;
}

.card .card-wrapper-table > div > span {
  font-size: 10px;
  line-height: 10px;
}

.card .card-wrapper-table > :first-child {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  vertical-align: middle;
}

.card .card-wrapper-table > :nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  vertical-align: bottom;
}

.card .card-wrapper-table > :last-child {
  width: 80%;
}

.card .flex-wrapper {
  display: flex;
  align-items: center;
}

.card .tunnel-progress-wrapper {
  padding: 15px 0px 0px;
}

.card .tunnel-progress {
  padding: 0px 10px;
  display: table;
  width: 100%;
  position: relative;
}

.card .tunnel-progress > * {
  display: table-cell;
  font-size: 20px;
  padding: 0px 10px;
  vertical-align: middle;
}

.card .tunnel-progress-wrapper > .percent {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.card .tunnel-progress > div span {
  font-size: 12px;
}

.card .tunnel-progress > div span.center {
  display: block;
  text-align: center;
  width: 100%;
}

.card .tunnel-progress > div span.left {
  display: block;
}

.card .tunnel-progress > div span.right,
.card .tunnel-progress > div > .progress-tunnel-percent span.right {
  display: block;
  text-align: right;
  width: 100%;
}

.card .progress,
.progress-tunnel-percent {
  height: 15px;
}

.card .tunnel-progress > div > .progress-tunnel-percent {
  height: 45px;
}

span.ap {
  position: relative;
}

span.ap_tooltip {
  position: absolute;
  display: none;
  transform: translateY(-50%);
  white-space: nowrap;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.45);
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
}

span.ap_tooltip.left {
  top: 50%;
  left: 45px;
}

span.ap_tooltip.right {
  top: 50%;
  right: 45px;
}

span.ap:hover .ap_tooltip {
  display: block;
}

span.ap_tooltip:after {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  border: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0.45);
  transform: translateY(-50%);
}

span.ap_tooltip.left:after {
  left: -10px;
  top: 50%;
  border: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0.45);
}

span.ap_tooltip.right:after {
  right: -10px;
  top: 50%;
  border: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0.45);
}

span.tunnel_name {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px !important;
  color: #666666;
  font-weight: 600;
  bottom: -1px;
}

.card.card-dashboard table:not(.geo-process) {
  height: calc(((100vh - 170px) / 2) - 60px);
}

.card .card-body.production,
.card.card-dashboard table.disable-footer {
  height: calc(((100vh - 170px) / 2) - 30px);
}

.card .card-body.production {
  display: table;
  width: 100%;
}

.card .card-body.production .data-wrapper {
  padding: 20px;
  display: table-cell;
  width: 25%;
  vertical-align: top;
  white-space: nowrap;
}

.card .card-body.production .data-wrapper > div {
  display: block;
  text-align: left;
}

.card .card-body.production .data-production {
  display: table;
  width: 95%;
  position: relative;
  height: 100%;
}

@media (max-width: 576px) {
  .card .card-body.production .data-production {
    width: 100%;
  }
}

.card .card-body.production .data-production .charts-wrapper {
  top: 50%;
  position: absolute;
  width: 100%;
  display: table;
  text-align: center;
  transform: translateY(-50%);
}

.card .card-body.production .data-production .charts-wrapper > div {
  display: table-cell;
  position: relative;
}

.card .card-body.production .data-production > div .centered-info,
.card.card-dashboard .efficiency .centered-info {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.card.card-dashboard .efficiency .centered-info > span {
  display: block;
  font-size: 12px;
  line-height: 17px;
}

.card.card-dashboard .efficiency .centered-info > :first-child {
  font-size: 22px;
  font-weight: 600;
}

.card.card-dashboard .efficiency .more-efficiency-info {
  margin-top: 5px;
  background-color: #f1f1f1;
  padding: 5px;
}

.card.card-dashboard .efficiency .more-efficiency-info > span {
  display: block;
}

.card.card-dashboard canvas {
  max-width: 17vh;
  max-height: 17vh;
  margin: 0 auto;
}

@media (max-height: 900px) {
  .card.card-dashboard .card-body {
    padding: 15px 10px;
  }

  .card .tunnel-progress > div > .progress-tunnel-percent {
    height: 25px;
  }

  .card.card-dashboard .efficiency {
    padding: 10px 0px;
  }

  .card.card-dashboard .efficiency > * {
    text-align: center;
    padding: 10px 15px;
  }
}

@media (max-height: 800px) {
  .card .card-wrapper-table .total-progress,
  .card .card-wrapper-table .total-efficiency {
    height: 25px;
    margin-top: -3px;
  }

  .card .card-wrapper-table .total-progress span.metter-ratio,
  .card .card-wrapper-table .total-efficiency span.metter-ratio {
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
  }

  .card .card-wrapper-table .total-progress span,
  .card .card-wrapper-table .total-progress div,
  .card .card-wrapper-table .total-efficiency span,
  .card .card-wrapper-table .total-efficiency div {
    height: 34px;
    line-height: 22px;
  }

  .card.card-dashboard .card-body {
    padding: 15px 10px;
  }

  .card.card-dashboard .card-body {
    padding: 15px 10px;
  }

  .card .tunnel-progress > div > .progress-tunnel-percent {
    height: 10px;
  }

  .card .tunnel-progress-wrapper {
    padding: 0px 0px 0px;
  }

  .card .card-wrapper-table > :nth-child(2) {
    font-size: 15px;
    font-weight: 600;
  }

  .card.card-dashboard .efficiency {
    margin: 0px;
    padding: 8px 0px;
  }

  .card.card-dashboard .efficiency .centered-info > :first-child {
    font-size: 16px;
    font-weight: 600;
  }

  .card.card-dashboard .efficiency .centered-info > span {
    font-size: 11px;
    line-height: 14px;
  }

  .card .card-body.production .data-production > div .centered-info,
  .card.card-dashboard .efficiency .centered-info {
    top: 46%;
  }

  .card.card-dashboard .efficiency > * {
    text-align: center;
    padding: 0px 15px;
  }

  .card.card-dashboard .efficiency .more-efficiency-info {
    margin-top: 0px;
    background-color: #ffffff;
    font-weight: 600;
  }

  .card.card-dashboard .efficiency .more-efficiency-info span {
    font-size: 11px;
    line-height: 11px;
  }

  .card .card-wrapper-table > :first-child {
    font-size: 12px;
  }
}

.card .card-body.production .data-production > div .centered-info .percent {
  font-size: 32px;
  font-weight: 600;
}

.card .card-body.production .data-production #yesterday-production,
.card .card-body.production .data-production .efficiency-chart {
  margin: 0 auto;
  max-width: calc((90vh) / 4);
  max-height: calc((90vh) / 4);
  min-width: 100px;
  min-height: 100px;
  width: 100% !important;
}

.card .card-body.reduce-padding {
  position: relative;
  padding: 6px 20px;
}

.card .card-footer.production {
  border-top: 2px solid #e6e6e6;
  padding: 0px 30px;
  text-align: right;
  position: relative;
}

.card.card-dashboard .red,
.card.card-dashboard .red * {
  color: #ff0000;
  font-weight: 600;
}

.card.card-dashboard .green,
.card.card-dashboard .green * {
  color: #8cc63f;
  font-weight: 600;
}

.card.card-dashboard .blue,
.card.card-dashboard .blue * {
  color: #29abe2;
  font-weight: 600;
}

.card.card-dashboard .more-info {
  background: #ff0000;
  padding: 2px 5px;
}

.card.card-dashboard .more-info:hover {
  background: #333333;
}

.card.card-dashboard .more-info * {
  color: #ffffff;
}

.card.card-dashboard #back-kpi i {
  font-size: 16px;
  margin-right: 5px;
}

.card.card-dashboard #back-kpi {
  padding: 5px 15px 5px 0px;
  text-align: left;
}

.card.card-dashboard #back-kpi i {
  color: #666666;
  font-size: 12px;
}

.card.card-dashboard #back-kpi:hover i {
  color: #ff0000;
}

.card .card-footer.production .elapsed {
  position: absolute;
  left: 5px;
  top: 7px;
  left: 30px;
  font-size: 14px;
}

.card .card-footer.production .estimated-wrapper {
  margin-top: 4px;
}

.card .card-footer.production .estimated-time {
  font-size: 16px;
  padding: 0px 10px;
}

.volumes-wrapper {
  height: calc((100vh - 400px) / 2);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 25px;
}

.progress,
.progress-tunnel-percent {
  height: 5px;
  position: relative;
  background: #e6e6e6;
}

.progress > span,
.progress-tunnel-percent > span {
  display: block;
  height: 35px;
  background-color: #29abe2;
  position: relative;
  overflow: hidden;
}

.progress,
.progress-tunnel-percent {
  margin-bottom: 0px;
}

.progress.dark {
  height: 5px;
  margin-bottom: 0px;
}

.progress.dark > span {
  background-color: #666666;
}

.progress-tunnel-percent > span {
  height: 100%;
}

.progress-tunnel-percent > span.right {
  position: absolute;
  right: 0;
  top: 0;
}

.progress-tunnel-percent span {
  background-color: maroon;
}

.progress-tunnel-percent span.right {
  transform-origin: right;
  background-color: darkgoldenrod;
}

.progress.red > span {
  background-color: #ff0000;
}

.progress.green > span {
  background-color: #8cc63f;
  height: 35px;
}

.data-wrapper span {
  display: block;
}

.data-wrapper > div :nth-child(odd) {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: -5px;
}

.data-wrapper > div > :nth-child(even) {
  font-size: 25px;
}

.data-wrapper div :nth-child(even) span {
  display: inline;
  text-transform: lowercase;
  font-size: 22px;
}

@media (max-width: 576px) {
  .card.card.card-dashboard {
    height: initial;
  }

  .card .card-body.production .data-wrapper {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .card .card-body.production .data-production {
    display: block;
    float: none;
    padding: 5px;
    margin-bottom: 15px;
  }

  .card .card-body.production .data-production .charts-wrapper {
    position: static;
    transform: translateY(0);
  }

  .card .card-body.production .data-wrapper {
    display: block;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    padding: 15px;
  }

  .card .card-body.production .data-production #production {
    max-width: 240px;
    max-height: 240px;
  }

  .card .card-body.production .data-wrapper div {
    width: 33.3333333%;
    display: inline-block;
    text-align: center;
  }

  .card .card-body.production .chart-wrapper {
    margin: 0 auto;
  }

  .data-wrapper > div :nth-child(odd) {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .data-wrapper > div :nth-child(even) span {
    font-size: 14px;
  }

  .data-wrapper > div > :nth-child(even) {
    font-size: 25px;
  }

  .card .card-footer.production .estimated-wrapper {
    margin-bottom: 10px;
  }

  .card .card-footer.production {
    margin: 0px 20px;
    padding: 6px 0px;
  }

  .card .card-footer.production .elapsed {
    top: 25px;
    left: 5px;
    font-size: 16px;
  }

  .card .card-footer.production .estimated-time {
    font-size: 20px;
    padding: 0px 10px;
  }

  .progress {
    height: 15px;
  }
}

#moved,
#expected,
#expected_today {
  width: 100%;
}

#moved,
#moved span,
#moved sup {
  color: #ff0000;
}

#expected,
#expected span,
#expected sup {
  color: #8cc63f;
}

#expected_today,
#expected_today span,
#expected_today sup {
  color: #29abe2;
}

table[id^='dash-table'] {
  margin-bottom: 0px;
}

table[id^='dash-table'] th,
table[id^='dash-table'] td {
  padding: 0px 20px;
  height: calc((100vh - 120px) / 16);
}

table[id^='dash-table'] thead tr th {
  text-align: left;
}

table[id^='dash-table'] tbody tr > :first-child {
  font-size: 16px;
  vertical-align: middle;
}

table[id^='dash-table'] tbody tr :nth-child(2) span {
  font-size: 12px;
  font-weight: 600;
}

table[id^='dash-table'] tbody tr :nth-child(2) div {
  height: 5px;
  background: #e6e6e6;
}

table[id^='dash-table'] tbody tr :nth-child(2) div div {
  height: 10px;
  background: #333333;
}

table[id^='dash-table'] tbody tr > :last-child span {
  background: none;
  display: inline-block;
  color: #666;
  font-size: 15px;
  line-height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

table[id^='dash-table'] thead tr > :last-child,
table[id^='dash-table'] tbody tr > :last-child {
  white-space: nowrap;
  text-align: right;
}

table[id^='dash-table'] tbody tr .stoped:before,
table[id^='dash-table'] tbody tr .available:before,
table[id^='dash-table'] tbody tr .maintenance:before,
table[id^='dash-table'] tbody tr .alarm:before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  margin-right: 5px;
}

table[id^='dash-table'] tbody tr td.td-btn {
  text-align: center;
}

#dash-table-vehicles thead tr > :nth-child(4),
#dash-table-vehicles tbody tr > :nth-child(4) {
  text-align: center;
}

#dash-table-vehicles tbody tr > :nth-child(4) span {
  font-size: 18px;
}

#dash-table-vehicles tbody tr .stoped:before,
#dash-table-vehicles tbody tr .available:before,
#dash-table-vehicles tbody tr .maintenance:before,
#dash-table-vehicles tbody tr .alarm:before {
  text-align: center;
  margin: 0px;
}

#dash-table-vehicles tr > :nth-child(2),
#dash-table-vehicles tr > :nth-child(3) {
  text-align: center !important;
  padding: 0px;
}

table[id^='dash-table'] tbody tr .available:before {
  background-color: #8cc63f;
}

table[id^='dash-table'] tbody tr .stoped:before {
  background-color: #ff0000;
}

table[id^='dash-table'] tbody tr .maintenance:before {
  background-color: transparent;
  background-image: url(../img/maintenance.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border-radius: 0px !important;
}

table[id^='dash-table'] tbody tr .alarm:before {
  background-color: transparent;
  background-image: url(../img/alarm.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border-radius: 0px !important;
}

table[id^='dash-table'] tbody tr .maintenance {
  background-color: transparent;
  margin: 0px !important;
}

#dash-table-kpi-detail td:not(:first-child),
#dash-table-kpi-detail th:not(:first-child) {
  text-align: center;
}

#dash-table tbody tr .maintenance {
  margin-left: -10px !important;
}

table[id^='dash-table'] tbody tr .available,
table[id^='dash-table'] tbody tr .stoped,
table[id^='dash-table'] tbody tr .maintenance {
  width: 50px;
  text-align: left;
}
/* DataTable */
table.dataTable.no-footer {
  border-bottom: 0px;
}

div#StopsGrid_info {
  margin-top: 0xp;
}

.dataTables_info {
  display: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #ff0000;
  color: white !important;
  border: 1px solid transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #232323;
  border: 1px solid transparent;
}

table.dataTable {
  width: 100% !important;
}

table.dataTable thead th,
table.dataTable thead td {
  background-color: whitesmoke;
  border: 0px;
}

table.dataTable thead th {
  text-align: left;
}

table.dataTable tbody td {
  padding: 8px 15px;
}

.dataTables_wrapper .dataTables_length {
  display: block;
  padding: 10px 15px;
  padding-right: 5px;
}

.dataTables_wrapper .dataTables_filter {
  padding: 8px 10px 5px;
}

.dataTables_wrapper .dataTables_filter label {
  text-transform: uppercase;
}

.dataTables_wrapper .dataTables_filter input {
  background-color: #f1f1f1;
  height: 40px;
  min-width: 250px;
  padding: 5px 10px;
}

.dataTables_length label {
  color: #666666;
  text-transform: uppercase;
}

.dataTables_length select {
  color: #232323;
  border: 1px solid #ff0000;
  margin: 0px 5px;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 15px 0px;
}

div[id^='dash-table'] .dataTables_length,
div[id^='dash-table'] .dataTables_filter {
  display: none;
}

div[id^='dash-table'] .dataTables_info {
  float: left;
  font-size: 12px;
  color: #666666;
  padding: 5px 20px;
}

div[id^='dash-table'] .dataTables_paginate {
  float: right;
  padding: 5px;
}

div[id^='dash-table'] .dataTables_paginate a {
  padding: 2px 5px;
  margin: 5px;
  background-color: #e6e6e6;
  cursor: pointer;
}

div[id^='dash-table'] .dataTables_paginate a.current {
  background-color: #666666;
  color: #ffffff;
}

div[id^='dash-table'] .dataTables_paginate a.disabled {
  display: none;
}

div[id^='dash-table'] .dataTables_paginate a:hover {
  background-color: #ff0000;
  color: #ffffff;
}

div[id^='dash-table'] .dataTables_paginate a.current:hover {
  background-color: #666666;
  color: #ffffff;
}

@media (max-width: 576px) {
  table[id^='dash-table'] tbody tr > :first-child {
    font-size: 14px;
  }

  table[id^='dash-table'] td,
  table[id^='dash-table'] th {
    height: 40px;
    padding: 2px 10px;
  }

  table[id^='dash-table'] tbody tr :nth-child(2) div {
    height: 5px;
  }

  table[id^='dash-table'] tbody tr :nth-child(2) span {
    font-size: 14px;
  }

  table[id^='dash-table'] tbody tr > :last-child span {
    font-size: 14px;
    line-height: 20px;
    margin-right: 5px;
    vertical-align: middle;
  }

  table[id^='dash-table'] tbody tr .available,
  table[id^='dash-table'] tbody tr .stoped,
  table[id^='dash-table'] tbody tr .maintenance {
    width: 30px;
  }

  table[id^='dash-table'] tbody tr .stoped:before,
  table[id^='dash-table'] tbody tr .available:before,
  table[id^='dash-table'] tbody tr .maintenance:before {
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    border-radius: 10px;
  }

  table[id^='dash-table'] tbody tr .maintenance:before {
    background-color: none;
    background-image: url(../img/maintenance.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border-radius: 0px !important;
  }
}

div#historic-messages_filter {
  display: none;
}

#historic-date-range {
  position: absolute;
  right: 10px;
  top: 48px;
  width: auto;
  z-index: 200;
}

div#historic-date-range input {
  padding: 5px;
}

.volumes-moved li,
.volumes-cut-moved li,
.volumes-fill-moved li {
  display: table;
  width: 100%;
  height: 5vh;
  min-height: 45px;
  position: relative;
}

.volumes-moved li > div,
.volumes-cut-moved li > div,
.volumes-fill-moved li > div {
  display: table-cell;
  vertical-align: middle;
  height: 30px;
}

.volumes-moved li > div .expected,
.volumes-fill-moved li > div .expected,
.volumes-cut-moved li > div .expected {
  float: right;
}

.volumes-moved li > div:nth-child(2) {
  vertical-align: top;
}

.volumes-moved li > :first-child,
.volumes-moved li > :last-child,
.volumes-cut-moved li > :first-child,
.volumes-cut-moved li > :last-child,
.volumes-fill-moved li > :first-child,
.volumes-fill-moved li > :last-child {
  font-size: 20px;
  line-height: 30px;
  vertical-align: middle;
}

.volumes-moved li > :first-child,
.volumes-cut-moved li > :first-child,
.volumes-fill-moved li > :first-child {
  position: relative;
  width: 50px;
  max-width: 50px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.volumes-moved li .tooltiptext,
.volumes-cut-moved li .tooltiptext,
.volumes-fill-moved li .tooltiptext {
  float: left;
  visibility: hidden;
  position: absolute;
  border-radius: 0px 5px 5px 5px;
  z-index: 100;
  left: 45px;
  top: 16px;
  background-color: #2323239e;
  color: white;
  padding: 5px 15px;
  text-align: center;
  width: initial !important;
}

.volumes-moved li .tooltiptext:before,
.volumes-cut-moved li .tooltiptext:before,
.volumes-fill-moved li .tooltiptext:before {
  content: '';
  display: block;
  height: 6px;
  width: 6px;
  position: absolute;
  left: -6px;
  top: 0;
  border: 3px solid transparent;
  border-top-color: #2323239e;
  border-right-color: #2323239e;
}

.weather-wrapper .tooltipweather {
  position: absolute;
  visibility: hidden;
  border-radius: 5px;
  z-index: 100;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2323239e;
  color: white;
  padding: 5px 15px;
  text-align: center;
  width: initial !important;
}

.weather-list > li:hover .tooltipweather {
  visibility: visible;
}

.volumes-moved li > :first-child:hover + .tooltiptext,
.volumes-cut-moved li > :first-child:hover + .tooltiptext,
.volumes-fill-moved li > :first-child:hover + .tooltiptext {
  visibility: visible;
}

.volumes-moved li > :last-child,
.volumes-cut-moved li > :last-child,
.volumes-fill-moved li > :last-child {
  width: 50px;
  text-align: right;
}

.volumes-moved li > :first-child,
.volumes-moved li > :last-child {
  font-size: 20px;
}

.volumes-cut-moved li > :first-child,
.volumes-cut-moved li > :last-child,
.volumes-fill-moved li > :first-child,
.volumes-fill-moved li > :last-child {
  font-size: 16px;
}

.volumes-moved li > :first-child,
.volumes-cut-moved li > :first-child,
.volumes-fill-moved li > :first-child {
  text-align: left;
}

.volumes-moved li > :last-child,
.volumes-cut-moved li > :last-child,
.volumes-fill-moved li > :last-child {
  text-align: right;
  color: #777;
}

.volumes-moved li > :nth-child(2),
.volumes-cut-moved li > :nth-child(2),
.volumes-fill-moved li > :nth-child(2) {
  width: calc(100% - 100px);
}

.volumes-cut-moved,
.volumes-fill-moved {
  margin-top: -10px;
  padding: 0px 15px;
}

.volumes-cut-moved {
  border-right: 2px solid #d9d9d9;
}

span.volume-cut,
span.volume-fill {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 15px;
}

span.volume-fill.sm {
  display: none;
}

@media (max-width: 767px) {
  span.volume-fill.md {
    display: none;
  }

  span.volume-fill.sm {
    display: block;
  }

  .volumes-wrapper {
    height: auto;
  }

  .card.card-dashboard-volumes {
    height: auto;
  }

  .volumes-cut-moved {
    border-right: 0px;
  }
}

.volume-cut {
  color: #ff0000;
}

.volume-fill {
  color: #8cc63f;
}

#weekly_chart,
#weekly_acumulated_chart,
#geo-chart {
  padding-right: 40px;
  transform: translateX(10px);
  height: calc(((100vh - 170px) / 2) - 70px);
}

.geo-process {
  margin-top: -5px;
  margin-bottom: 5px;
  height: 20px;
  margin-left: 90px;
  margin-right: 40px;
}

@media (max-height: 700px) {
  #geo-chart {
    margin-top: 10px;
  }

  .geo-process {
    height: 10px;
    margin-top: 5px;
  }
}

.geo-process table {
  height: 20px !important;
  table-layout: fixed;
}

.geo-process table td {
  border: 0px;
}

#anual_chart,
#anual_chart_acumulated {
  padding-right: 20px;
  height: calc((100vh - 250px) / 2);
}

.geo-process td {
  position: relative;
  overflow: visible !important;
}

.geo-process > table tr > td > span {
  position: absolute;
  display: none;
  text-align: center;
  z-index: 100;
  border: 1px;
  background-color: #0000007a;
  padding: 5px 10px;
  color: white;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
}

.geo-process > table tr > td:hover > span {
  display: block;
  white-space: nowrap;
}

.weather-list {
  white-space: nowrap;
  font-size: 0px;
  transform: translateX(83px);
  margin-bottom: 0px;
  margin-top: -20px;
}

.weather-list li {
  display: inline-block;
  text-align: left;
  width: calc((100% - 121px) / 7);
  position: relative;
  text-align: center;
}

.weather-list li img {
  width: 35px;
  margin-top: -15px;
  margin-bottom: -5px;
}

#dash-table-vehicles tr,
#dash-table-vehicles th,
#dash-table-vehicles td {
  height: 35px;
}

#dash-table-vehicles img {
  width: 35px;
  height: 35px;
  min-width: 30px;
}

.geo-process > table tr > td > span {
  position: absolute;
  display: none;
  z-index: 100;
  border: 1px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
  padding: 5px 10px;
  color: #232323;
  top: 40px;
  left: 50%;
  transform: translateX(-15%);
  box-shadow: 1px 1px 2px 0px #0000007a;
}

.geo-process > table tr > td:hover > span {
  display: block;
}

@media (max-width: 576px) {
  .volumes-moved li > div,
  .volumes-cut-moved li > div,
  .volumes-fill-moved li > div {
    height: 30px;
  }

  .progress.red,
  .progress.green {
    height: 20px;
  }

  .volumes-moved li > :first-child,
  .volumes-moved li > :last-child,
  .volumes-cut-moved li > :first-child,
  .volumes-cut-moved li > :last-child,
  .volumes-fill-moved li > :first-child,
  .volumes-fill-moved li > :last-child {
    line-height: 30px;
  }

  .card .card-body.weather-wrapper {
    padding: 30px 10px 10px;
  }
}

#dash-table-vehicles img {
  width: 85px;
  height: 35px;
  min-width: 35px;
}

#dash-table-vehicles tbody tr > :nth-child(4) span {
  font-size: 14px;
}

#weekly_canvas {
  transform: translateX(12px);
}

.wi {
  line-height: 1.4;
  font-size: 25px;
  color: #666666;
}
/* openweathermap */
.owm-01d:before {
  content: '\f00d';
}

.owm-02d:before {
  content: '\f002';
}

.owm-03d:before {
  content: '\f041';
}

.owm-04d:before {
  content: '\f013';
}

.owm-09d:before {
  content: '\f01a';
}

.owm-10d:before {
  content: '\f008';
}

.owm-11d:before {
  content: '\f01e';
}
/*f010*/
.owm-13d:before {
  content: '\f01b';
}

.owm-50d:before {
  content: '\f003';
}

.owm-01n:before {
  content: '\f02e';
}

.owm-02n:before {
  content: '\f086';
}

.owm-03n:before {
  content: '\f041';
}

.owm-04n:before {
  content: '\f013';
}

.owm-09n:before {
  content: '\f01a';
}

.owm-10n:before {
  content: '\f028';
}

.owm-11n:before {
  content: '\f01e';
}
/*f02d*/
.owm-13n:before {
  content: '\f01b';
}

.owm-50n:before {
  content: '\f04a';
}

.owm-nd:before {
  content: '\f07b';
}

.no-data span {
  text-align: center;
  padding: 50px;
  font-size: 35px;
  font-weight: 200;
  font-style: italic;
  color: #666666;
}

.no-processes-data {
  display: block;
  text-align: center;
  padding: 15px;
  font-style: italic;
  font-size: 18px;
  font-weight: 200;
}

@media (max-height: 600px) {
  .card.card-dashboard,
  .card.card-dashboard-volumes {
    height: auto;
    position: relative;
    min-height: 300px;
  }

  .card .card-body.production .data-production {
    height: 270px;
  }

  .card .card-body.production .data-production #yesterday-production,
  .card .card-body.production .data-production .efficiency-chart {
    height: 200px;
    width: 200px;
    max-height: 200px;
    max-width: 200px;
  }

  #weekly_chart,
  #weekly_acumulated_chart {
    height: 200px;
  }

  .card .card-body.production,
  .card.card-dashboard table.disable-footer {
    height: 200px;
  }

  .volumes-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 25px;
  }
}

/* RTNav */
.tree-wrapper {
  position: absolute;
  top: 135px;
  left: 10px;
  z-index: 200;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100% - 190px);
  padding: 20px 10px;
  overflow-y: auto;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}

.btn-tree {
  position: absolute;
  margin-left: 220px;
  top: 15px;
  display: none;
  width: 20px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  color: white;
  border-radius: 5px;
  font-weight: 900;
  transition: all 0.4s ease-in-out;
}

.btn-tree:after {
  content: '\f053';
  font-family: 'Font Awesome 5 Free';
  display: block;
  width: 20px;
  height: 40px;
  padding: 11px 0px;
}

.btn-tree.closed {
  margin-left: 10px;
}

.btn-tree.closed:after {
  content: '\f054';
}

.btn-tree:hover {
  background-color: rgba(104, 104, 104, 0.5);
}

.btn-tree i {
  color: white;
}

.tree-wrapper.menu-open {
  margin-left: 260px;
}

#jstree * {
  text-transform: uppercase;
  color: white;
}

#jstree *:hover {
  color: red;
}

#jstree *.jstree-clicked {
  font-weight: 600;
  color: white !important;
}

#menu-info {
  width: 250px;
  padding: 0px 25px 10px;
  text-align: center;
}

#menu-info #menu-info-name {
  font-weight: 600;
  height: auto;
  font-size: 20px;
  margin-top: -5px;
}

span#menu-info-model {
  text-transform: uppercase;
  font-size: 14px;
  display: block;
}

#menu-info-status {
  display: block;
  padding: 5px;
  margin: 5px 20px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
}

#menu-info-status.status-on {
  background: #8cc63f;
}

#menu-info-status.status-off {
  background: #666;
}

#menu-info-status.status-mant {
  background: #f7931e;
}

span#menu-info-status * {
  vertical-align: bottom;
}

span#menu-info-gps {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

div#menu-info-alerts {
  text-align: center;
  padding: 5px 0px;
  border-top: 1px solid #f1f1f1;
  margin-top: 10px;
}

a.menu-info-alerts-text {
  text-transform: uppercase;
  color: red;
}

a.menu-info-alerts-text:before {
  content: '';
  background-image: url(../img/menu_alert.svg);
  background-repeat: no-repeat;
  background-size: 25px;
  display: inline-block;
  top: 7px;
  margin-right: 10px;
  position: relative;
  width: 25px;
  height: 25px;
}

.menu-info-wrapper {
  background-color: #f1f1f1;
  padding: 5px;
  margin-top: 5px;
}

.menu-info-wrapper > span,
.menu-info-wrapper > div > span {
  display: block;
  color: #666666;
  font-size: 14px;
}

.menu-info-wrapper > div > span sup {
  color: #666666;
}

.menu-info-wrapper > span b,
.menu-info-wrapper > div > span b {
  text-transform: uppercase;
  font-weight: 600;
}

.menu-info-wrapper > div > span b {
  display: block;
}

.form-row.menu-info-heatmaps span {
  display: inline-block;
  margin: 0px;
}

.form-row.menu-info-heatmaps {
  padding: 0px;
  margin-top: 25px;
}

span#menu-info-paths,
span#menu-info-clearpath {
  display: block;
  margin-bottom: 5px;
}

a.btn-dismiss-alerts {
  padding: 5px !important;
  margin: 0px !important;
  width: 35px;
  display: inline-block;
  color: #f00 !important;
  border: none !important;
}

a.btn-dismiss-alerts i {
  margin: 0px 5px;
  color: #f00 !important;
}

@media (max-width: 770px) {
  .tree-wrapper.menu-open {
    margin-left: 0px;
  }

  .tree-wrapper {
    top: 10px;
    height: calc(100% - 20px);
  }

  .tree-wrapper.map-key {
    bottom: 50px !important;
  }

  .tree-wrapper.closed {
    transform: translateX(-220px);
  }

  .btn-tree {
    display: block;
  }
}

/*  Settings */
.row-settings > div {
  padding-left: 5px;
  padding-right: 5px;
}

.setting-btn {
  display: table;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 25px 10px 25px 20px;
  height: 115px;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.05);
  text-align: middle;
  margin: 10px 0px;
}

.setting-btn:hover,
.setting-btn:hover > span {
  background-color: #ff0000;
  color: white;
}

.setting-btn > * {
  display: table-cell;
  vertical-align: middle;
}

.setting-btn > div {
  min-width: 60px;
}

.setting-btn > span {
  padding: 10px;
  text-transform: uppercase;
}

.setting-btn .icon.polygon {
  background-image: url('../img/polygons_icon.svg');
}

.setting-btn:hover .icon.polygon {
  background-image: url('../img/polygons_icon_white.svg');
}

/*
    .setting-btn .icon.project {
        background-image: url('../img/menu_project.png');
    }

    .setting-btn:hover .icon.project {
        background-image: url('../img/menu_project_white.png');
    }
    */

.setting-btn .icon.section {
  background-image: url('../img/sections_icon.svg');
}

.setting-btn:hover .icon.section {
  background-image: url('../img/sections_icon_white.svg');
}

.setting-btn .icon.equipment {
  background-image: url('../img/equipment_icon.svg');
}

.setting-btn:hover .icon.equipment {
  background-image: url('../img/equipment_icon_white.svg');
}

.setting-btn .icon.equipment_cat {
  background-image: url('../img/eqcat_icon.svg');
}

.setting-btn:hover .icon.equipment_cat {
  background-image: url('../img/eqcat_icon_white.svg');
}

.setting-btn .icon.subcontractor {
  background-image: url('../img/subcontractors_icon.svg');
}

.setting-btn:hover .icon.subcontractor {
  background-image: url('../img/subcontractors_icon_white.svg');
}

.setting-btn .icon.type {
  background-image: url('../img/polygon_types_icon.svg');
}

.setting-btn:hover .icon.type {
  background-image: url('../img/polygon_types_icon_white.svg');
}

.setting-btn .icon.overlay {
  background-image: url('../img/menu_layer.svg');
}

.setting-btn:hover .icon.overlay {
  background-image: url('../img/menu_layer_white.svg');
}

.setting-btn .icon.role {
  background-image: url('../img/roles_icon.svg');
}

.setting-btn:hover .icon.role {
  background-image: url('../img/roles_icon_white.svg');
}

.setting-btn .icon.equipment_model {
  background-image: url('../img/eqmodels_icon.svg');
}

.setting-btn:hover .icon.equipment_model {
  background-image: url('../img/eqmodels_icon_white.svg');
}

.setting-btn .icon.crew {
  background-image: url('../img/crews_icon.svg');
}

.setting-btn:hover .icon.crew {
  background-image: url('../img/crews_icon_white.svg');
}

.setting-btn .icon.material {
  background-image: url('../img/materials_icon.svg');
}

.setting-btn:hover .icon.material {
  background-image: url('../img/materials_icon_white.svg');
}

.setting-btn .icon.topup {
  background-image: url('../img/topup_icon.svg');
}

.setting-btn:hover .icon.topup {
  background-image: url('../img/topup_icon_white.svg');
}

.setting-btn .icon.tunnel {
  background-image: url('../img/tunnel_icon.svg');
}

.setting-btn:hover .icon.tunnel {
  background-image: url('../img/tunnel_icon_white.svg');
}

.setting-btn .icon.tunnel_ap {
  background-image: url('../img/tunnel_ap_icon.svg');
}

.setting-btn:hover .icon.tunnel_ap {
  background-image: url('../img/tunnel_ap_icon_white.svg');
}

.setting-btn .icon.cost {
  background-image: url('../img/costs_icon.svg');
}

.setting-btn:hover .icon.cost {
  background-image: url('../img/costs_icon_white.svg');
}

.setting-btn .icon.company {
  background-image: url('../img/company_icon.svg');
}

.setting-btn:hover .icon.company {
  background-image: url('../img/company_icon_white.svg');
}

.setting-btn .icon.volume {
  background-image: url('../img/menu_volume.svg');
}

.setting-btn:hover .icon.volume {
  background-image: url('../img/menu_volume_white.svg');
}

.setting-btn .icon.info-panel {
  background-image: url('../img/menu_panel.svg');
}

.setting-btn:hover .icon.info-panel {
  background-image: url('../img/menu_panel_white.svg');
}

.setting-btn .icon.user {
  background-image: url('../img/menu_user.svg');
}

.setting-btn:hover .icon.user {
  background-image: url('../img/menu_user_white.svg');
}

.setting-btn .icon.userrole {
  background-image: url('../img/menu_roles.svg');
}

.setting-btn:hover .icon.userrole {
  background-image: url('../img/menu_roles_white.svg');
}

.setting-btn .icon.support {
  background-image: url('../img/support.svg');
}

.setting-btn:hover .icon.support {
  background-image: url('../img/support_white.svg');
}

.setting-btn .icon.geotype {
  background-image: url('../img/geotype.svg');
}

.setting-btn:hover .icon.geotype {
  background-image: url('../img/geotype_white.svg');
}

.setting-btn .icon.measure {
  background-image: url('../img/convergence.svg');
}

.setting-btn:hover .icon.measure {
  background-image: url('../img/convergence_white.svg');
}

.setting-btn .icon.planning {
  background-image: url('../img/menu_planning.svg');
}

.setting-btn:hover .icon.planning {
  background-image: url('../img/menu_planning_white.svg');
}

.setting-btn .icon.icon-big {
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  width: 60px !important;
  height: 60px !important;
  margin-right: 25px;
}

.map-avail * {
  color: #fff !important;
}

.mapkey-avail-hidden {
  right: 10px !important;
}

/* Mapping */

.type-control {
  position: absolute;
  display: table;
  width: 100px;
  top: 10px;
  left: 10px;
  z-index: 500;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
}

.type-control button {
  display: table-cell;
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  padding: 2px 5px;
}

.type-control button:hover {
  background-color: #232323;
  color: white;
}

.type-control button.selected {
  background-color: #ff0000;
  color: white;
}

.type-control button.selected:hover {
  background-color: #232323;
  color: white;
}

.map-wrapper .show-elevation {
  position: absolute;
  bottom: 10px;
  left: 15px;
  background: #f1f1f1;
  padding: 5px 10px;
  text-transform: uppercase;
}

.map-wrapper {
  position: relative;
  height: calc(50vh);
  overflow: hidden;
}

.map-wrapper.hide-elevation {
  height: calc(100vh - 125px) !important;
}

.map-wrapper .show-elevation:before {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  margin-right: 10px;
  color: #ff0000;
}

.map-wrapper.hide-elevation .show-elevation:before {
  content: '\f106';
}

.map-legend {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 250px;
  background-color: rgba(0, 0, 0, 0.45);
  margin: 10px 50px 40px;
  padding: 12px;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}

.map-legend.open {
  transform: translateX(-280px);
}

.map-legend li {
  color: white;
  text-transform: uppercase;
  font-weight: 200;
}

.map-legend .legend-title {
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin-top: -5px;
  margin-bottom: 10px;
  display: block;
}

.map-legend ul.legend-list {
  margin-bottom: 0px;
}

.map-legend ul.legend-list > li:not(:last-child) {
  font-size: 13px;
}

.map-legend ul.legend-list > li:not(:last-child) {
  margin-bottom: 2px;
}

span.legend-color {
  display: inline-block;
  width: 13px;
  height: 17px;
  margin-bottom: -5px;
  margin-right: 10px;
  border-radius: 3px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.right-info {
  position: fixed;
  padding: 20px 20px 10px;
  z-index: 900;
  text-align: center;
  right: -280px;
  background-color: rgba(255, 255, 255, 0.95);
  width: 250px;
  height: calc(100vh - 125px);
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
}

.right-info.open {
  transform: translateX(-280px);
}

.right-info .close-info-btn {
  position: absolute;
  padding: 15px;
  top: 0;
  left: 0;
}

.right-info .close-info-btn:hover {
  cursor: pointer;
}

.right-info .info-literal {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
}

.right-info img {
  display: block;
  margin: 0 auto 10px;
  width: 50px;
}

.right-info .section-data {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 5px;
}

.right-info .sensor-data {
  margin-top: 10px;
  text-align: left;
  background-color: white;
  border: 1px solid #666666;
  border-radius: 5px;
}

.right-info .sensor-data .content-data {
  padding: 10px;
}

.right-info .sensor-data .content-data span {
  display: block;
}

.right-info .sensor-data .content-data ul li {
  font-style: italic;
  margin-left: 10px;
}

.right-info .sensor-data > :first-child {
  display: block;
  padding: 2px 10px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  border-bottom: 1px solid #ff0000;
}

.right-info .sensor-data li.no-data {
  color: #666;
  text-transform: uppercase;
}

.right-info span.no-data {
  top: 50px;
  position: relative;
  font-size: 19px;
  font-weight: 100;
  text-align: center;
  font-style: italic;
}

.right-info #geo-data {
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
  border: 2px solid rgba(0, 0, 0, 0.15);
}

.right-info .chart-process-wrapper {
  padding: 30px 20px;
  width: 100%;
  height: 230px;
}

.right-info div#loading-info {
  padding-top: 50px;
}

section#elevation {
  position: relative;
  display: block;
  height: calc(50vh - 125px) !important;
  border-top: 4px solid #666666;
  background-color: white;
  padding: 15px 0px 0px;
  overflow: hidden;
}

section#elevation.hide-elevation {
  display: none;
}

section#elevation #elevation-container {
  margin: 0 auto;
  height: calc(50vh - 145px);
  width: 100%;
  max-width: calc(100vw - 250px);
}

@media (max-width: 770px) {
  section#elevation #elevation-container {
    max-width: calc(100vw - 50px);
  }
}

/* RTTracking */

.st0 {
  fill: #666666;
}

.st1 {
  fill: #8cc63f;
}

.st2 {
  fill: #ffffff;
}

.st3 {
  fill: #cccccc;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}

.st4 {
  fill: #232323;
}

.st5 {
  opacity: 0.15;
}

.st6 {
  fill: #ff0000;
}

.st81 {
  fill: none;
  stroke: #8a3737;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st91 {
  fill: none;
  stroke: #082da5;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st101 {
  fill: none;
  stroke: #259a09;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st111 {
  fill: none;
  stroke: #500583;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.process_status_wrapper {
  text-align: center;
}

.process_status_wrapper h2 {
  font-size: 25px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 25px;
  font-weight: 600;
  padding: 5px;
}

.process_status_wrapper .process_name {
  padding: 5px 35px;
  display: inline-block;
  margin-top: 15px;
  border-radius: 10px;
  font-size: 18px;
  font-style: italic;
  background-color: #ffffff;
}

�
/* Scheduler personalization */
.sked-tape__location-text {
  font-family: 'Arial Narrow';
  font-weight: 500;
}

.sked-tape__event-row {
  background-color: #f1f1f1;
  height: 27px;
}

.sked-tape__location-text {
  font-size: 14px;
}

.sked-tape__location {
  background-color: #ffffff;
  line-height: 27px;
  height: 27px;
}

.sked-tape__indicator {
  display: none !important;
}

.sked-tape__event {
  border: 2px solid #e6ad7d;
  background-color: #e6c7ad;
}

.sked-tape__event:hover {
  background-color: #666666;
  border-color: #666666;
}

.sked-tape__event.failure-class {
  border: 2px solid #ff0000;
  background-color: #ff5555;
}

.sked-tape__event.process-class-inprocess {
  border: 2px solid rgb(108, 179, 15);
  background-color: #8cc63f;
}

.sked-tape__grid > li {
  background-image: linear-gradient(to right, #e5e5e5 1px, #e5e5e5 1px),
    linear-gradient(to right, #e5e5e5 1px, #e5e5e5 1px),
    linear-gradient(to right, #e5e5e5 1px, #e5e5e5 1px),
    linear-gradient(to right, #e5e5e5 1px, #e5e5e5 1px),
    linear-gradient(to right, #e5e5e5 1px, #e5e5e5 1px),
    linear-gradient(to right, #e5e5e5 1px, #e5e5e5 1px),
    linear-gradient(to right, #e5e5e5 1px, #e5e5e5 1px);
  background-size:
    1px 100%,
    1px 100%,
    1px 100%,
    1px 100%,
    1px 100%,
    1px 100%,
    1px 100%;
  background-position:
    0 0,
    100% 0,
    20% 0,
    40% 0,
    60% 0,
    80% 0,
    100% 0;
}

.sked-tape__gap {
  display: none;
}

.sked-tape__center {
  font-size: 0px;
}

.sked-tape__timeline {
  overflow: hidden;
}

.sked-tape__event-row:first-child .sked-tape__event {
  z-index: 200;
  overflow: visible;
}

.sked-tape__intersection {
  display: none;
}

svg#svg_status {
  width: 100%;
  height: auto;
}

.bootstrap-datetimepicker-widget table thead th,
.bootstrap-datetimepicker-widget table tbody td {
  border-bottom: 0px;
  border-right: 0px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #ff0000;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #666666;
}

.bootstrap-datetimepicker-widget .btn:hover,
.bootstrap-datetimepicker-widget .btn:hover.btn-gray,
.bootstrap-datetimepicker-widget .btn:hover.btn-darkgray {
  background-color: transparent;
}

.bootstrap-datetimepicker-widget .btn.btn-primary:hover,
.bootstrap-datetimepicker-widget .btn.btn-primary:hover.btn-gray,
.bootstrap-datetimepicker-widget .btn.btn-primary:hover.btn-darkgray {
  background-color: #cc0000;
}

.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td span.active {
  background: #f00;
  color: white;
}

.bootstrap-datetimepicker-widget .btn-primary {
  color: #fff;
  background-color: #ff0000;
  border: 0px;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  text-transform: initial;
}

.logo_preview,
.header_preview {
  margin-top: 10px;
  width: 100%;
  background: repeating-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0) 5px,
    rgba(0, 0, 0, 0) 15px,
    #f1f1f1 15px,
    #f1f1f1 25px
  );
  padding: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
}

.logo_preview div.no_image,
.header_preview div.no_image {
  text-align: center;
  font-style: italic;
}

.logo_preview .image_wrapper,
.header_preview .image_wrapper {
  position: relative;
  background-color: white;
  max-width: 200px;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.logo_preview .image_wrapper button,
.header_preview .image_wrapper button {
  position: absolute;
  background-color: red;
  width: 25px;
  height: 25px;
  line-height: 28px;
  border-radius: 50%;
  right: -10px;
  top: -10px;
}

.logo_preview .image_wrapper button i,
.header_preview .image_wrapper button i {
  color: white;
}

/* MAPBOX */
.mapboxgl-marker:hover {
  cursor: pointer !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

#map-preview .mapboxgl-canvas-container .mapboxgl-canvas {
  top: 0px;
  left: 0px;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  bottom: 0px;
  right: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  border-radius: 3px 3px 12px 3px;
}

.mapboxgl-compact a {
  margin: 0px !important;
  padding: 0px !important;
  display: inline-block !important;
  width: 100%;
  border: 0px solid #fff !important;
}

.btn-red i.fas {
  color: #fff;
}

.mapboxgl-ctrl-top-right {
  top: 0px !important;
  transition: all 0.4s ease-in-out;
}

.mapboxgl-ctrl-top-right.open {
  transform: translateX(-250px);
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-clockwise,
.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-anticlockwise,
.mapboxgl-ctrl-icon > .mapboxgl-ctrl-pitch0,
.mapboxgl-ctrl-icon > .mapboxgl-ctrl-pitch60 {
  width: 20px;
  height: 20px;
  margin: 5px;
  display: inline-block;
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-clockwise {
  background-image: url('../img/arrow-cw.svg');
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-arrow-anticlockwise {
  background-image: url('../img/arrow-anticw.svg');
}

.mapboxgl-ctrl-span {
  display: block;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 0px !important;
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-pitch0 {
  background-image: url('../img/pitch_0.svg');
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-pitch60 {
  background-image: url('../img/pitch_60.svg');
}

.slidecontainer {
  width: 100%;
}

.mapboxgl-slider {
  -webkit-appearance: none;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  transform: rotate(270deg);
  height: 16px;
  width: 100px !important;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: -35px;
  margin-right: -41px;
}

.mapboxgl-slider:hover {
  opacity: 1;
}

.mapboxgl-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #ff0000;
  cursor: pointer;
}

.mapboxgl-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ff0000;
  cursor: pointer;
}

.crud-list th td,
.crud-list tr td {
  text-align: center;
}

.mapboxgl-ctrl-top-right {
  z-index: 999 !important;
}

div#tunnel-elevation-selector {
  position: absolute;
  z-index: 899;
  right: 0;
  top: -1px;
  width: 220px;
}

div#tunnel-elevation-selector .select {
  height: 30px;
}

div#tunnel-elevation-selector .select-styled {
  padding: 3px 14px;
  border: 1px solid #666666;
}

div#tunnel-elevation-selector .select-styled:after {
  padding: 0px 8px;
  line-height: 15px;
}

.marker_path {
  width: 21px;
  height: 21px;
  background-image: url(../img/marker.png);
  opacity: 0.7;
}

.marker_path.selected {
  opacity: 1;
  background-image: url(../img/marker2.png);
}

.marker_path_Shatter {
  width: 21px;
  height: 21px;
  background-image: url(../img/marker_blue.png);
  opacity: 0.7;
}

.marker_path_Shatter.selected {
  opacity: 1;
  background-image: url(../img/marker2.png);
}

.marker_path_Complete_Section {
  width: 21px;
  height: 21px;
  background-image: url(../img/marker_green.png);
  opacity: 0.7;
}

.marker_path_Complete_Section.selected {
  opacity: 1;
  background-image: url(../img/marker2.png);
}

.marker_base_station,
.marker_local_server,
.marker_ble,
.marker_ccs {
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  box-sizing: content-box;
}

.marker_base_station:hover,
.marker_local_server:hover,
.marker_ble:hover,
.marker_ccs:hover {
  border: 1px solid #666;
}

.marker_base_station.selected,
.marker_local_server.selected,
.marker_ble.selected,
.marker_ccs.selected {
  width: 50px;
  height: 50px;
  opacity: 1;
  z-index: 500;
  border: 5px solid #f00;
}

.marker_base_station {
  background-image: url(../img/base_station_2.png);
}

.marker_local_server {
  background: url(../img/local_server_marker.svg);
}

.marker_ble {
  background: url(../img/ble_marker.svg);
}

.marker_ccs {
  background: url(../img/ccs_marker.svg);
}

.marker_cctv {
  width: 45px;
  height: 45px;
  background: url(../img/cctv_map.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.marker_base_station.ko:before,
.marker_local_server.ko:before,
.marker_ble.ko:before,
.marker_ccs.ko:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-color: #990000;
  border: 1px solid black;
  border-radius: 7px;
  top: 5px;
  right: -6px;
  position: absolute;
}

.marker_base_station.ok:before,
.marker_local_server.ok:before,
.marker_ble.ok:before,
.marker_ccs.ok:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-color: #8cc63f;
  border: 1px solid black;
  border-radius: 7px;
  top: 5px;
  right: -6px;
  position: absolute;
}

#status-data,
#network-data,
#power-data,
#phone-data,
#sai-data,
#battery-data {
  padding: 5px;
  border-radius: 5px;
}

#status-data > span,
#network-data > span,
#phone-data > span,
#sai-data > span,
#battery-data > span,
#power-data > span {
  color: white;
}

#status-data.red,
#network-data.red,
#phone-data.red,
#sai-data.red,
#battery-data.red,
#power-data.red {
  background-color: rgba(153, 0, 0, 0.67);
  border: 2px solid #990000;
}

#status-data.green,
#network-data.green,
#phone-data.green,
#sai-data.green,
#battery-data.green,
#power-data.green {
  background-color: rgba(140, 198, 63, 0.67);
  border: 2px solid #8cc63f;
}

#status-data.gray,
#network-data.gray,
#phone-data.gray,
#sai-data.gray,
#battery-data.gray,
#power-data.gray {
  background-color: rgba(102, 102, 102, 0.67);
  border: 2px solid #666666;
}

#status-data.orange,
#network-data.orange,
#phone-data.orange,
#sai-data.orange,
#battery-data.orange,
#power-data.orange {
  background-color: rgba(255, 165, 0, 0.67);
  border: 2px solid #ffa500;
}

.panel-preview {
  background-color: #232323;
  padding: 10px 25px;
  border-radius: 5px;
  margin: 20px;
  border: 10px solid #4a4a4a;
}

.panel-preview div,
.panel-preview div marquee {
  color: yellowgreen;
  font-size: 50px;
  font-family: 'led_dot-matrixregular';
  white-space: nowrap;
  overflow: hidden;
}

.panel-preview div {
  margin: 5px 0px;
}

.panel-preview div marquee {
  line-height: 50px;
}

.panel-btns > div {
  padding: 10px;
  text-align: center;
}

td.no-ap {
  background-color: #fff5f5;
  font-style: italic;
  font-weight: 100;
}

td.no-ap a {
  font-style: normal;
}

/* REPORTING */

.global-information span {
  font-size: 18px;
}

.global-information > :nth-child(3) {
  margin-top: 20px;
}

.global-information span.green {
  color: #8cc63f;
}

.global-information span.red {
  color: #990000;
}

.efficiency {
  text-align: center;
}

.efficiency .title {
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
}

.efficiency .centered-info-reporting {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -15%);
}

.efficiency .centered-info-reporting .percent {
  font-size: 35px;
  font-weight: 600;
  display: block;
  margin-bottom: -5px;
}

.efficiency canvas {
  width: 100%;
  margin: 0 auto;
}

.family-resume {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.performance-resume {
  background-color: #e6e6e6;
  border: 1px solid white;
  border-radius: 5px;
}

.family-resume,
.family-detail,
.family-indicator,
.performance-resume {
  display: table;
  width: 100%;
}

.family-detail,
.performance-detail {
  border-bottom: 1px solid #e6e6e6;
}

.family-resume:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.performance-resume:hover {
  background-color: #f97e7e;
  cursor: pointer;
}

.family-resume:after,
.performance-resume:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 20px;
  display: block;
  padding: 10px;
  width: 10px;
  height: 10px;
  top: 0;
  margin-right: 20px;
}

.performance-resume:after {
  padding: 3px;
}

.family-resume.open {
  background-color: #f1f1f1;
}

.performance-resume.open {
  background-color: #da7575;
}

.family-resume.open:hover {
  background-color: #e6e6e6;
}

.performance-resume.open:hover {
  background-color: #bd4141;
}

.family-resume.open:after,
.performance-resume.open:after {
  content: '\f106';
}

.family-resume > div,
.family-detail > div,
.family-indicator > div {
  display: table-cell;
  padding: 10px 20px;
}

.performance-resume > div,
.performance-detail > div {
  display: table-cell;
  padding: 5px 20px;
  vertical-align: middle;
}

.family-resume > div {
  font-size: 18px;
  text-transform: uppercase;
}

.performance-resume > div {
  font-size: 15px;
  text-transform: uppercase;
}

.family-resume > :first-child,
.family-detail > :first-child,
.family-indicator > :first-child,
.performance-resume > :first-child,
.performance-detail > :first-child {
  width: 100%;
}

.family-resume > :not(:first-child),
.family-detail > :not(:first-child),
.family-indicator > :not(:first-child),
.performance-resume > :not(:first-child),
.performance-detail > :not(:first-child) {
  min-width: 145px;
  text-align: right;
}

.family-detail > div,
.performance-detail > div {
  color: #666666;
  font-weight: 200;
}

.family-detail > :not(:first-child),
.family-indicator > :not(:first-child) {
  padding-right: 50px;
}

.family-indicator > :not(:first-child) {
  font-weight: 600;
  background-color: rgba(143, 236, 18, 0.22);
}

.family-detail.had-value > div,
.performance-detail.had-value > div {
  color: black;
  font-weight: 600;
}

h3.resume-h3 {
  margin-top: 5px;
}

.total-indicator {
  text-align: center;
}

.total-indicator > div {
  padding: 10px;
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 9px;
  position: absolute;
  top: 15px;
  right: 20px;
}

/*  Evacuation control */

.card-body.presence-control {
  position: relative;
  padding: 5px 5px 15px;
}

.card-body.access-control {
  position: relative;
  padding: 5px 5px 15px;
}

.card-body.card-ventilation {
  position: relative;
  padding: 15px;
}

label.slider-switch {
  margin-bottom: 0px;
  font-weight: 700;
  width: 60px;
  height: 30px;
}

label.auto-switch > input + span {
  z-index: 500;
}

.card-body.evacuation-card {
  padding: 5px;
}

.card-body.evacuation-card .activation-btns {
  text-align: center;
  padding: 10px;
}

.card-body.evacuation-card button {
  display: inline-block;
  width: 30%;
  padding: 8px 10px 5px;
  margin: 5px 10px;
}

.btn.warning > span,
.btn.test > span,
.btn.panel > span {
  font-size: 10px;
  color: white;
  text-transform: capitalize;
}

.btn.warning:before,
.btn.test:before,
.btn.panel:before {
  width: 40px;
  height: 40px;
  font-size: 24px;
  display: block;
  position: relative;
  top: 9px;
  left: 0;
}

.btn.warning,
.btn.test,
.btn.panel {
  text-transform: uppercase;
  font-weight: 600;
}

.base-station-wrapper {
  padding: 10px;
  margin-top: 15px;
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  background-color: #f1f1f1;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
}

.base-station {
  position: relative;
  background-color: white;
  display: inline-block;
  padding: 5px;
  min-width: 90px;
  height: 100px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.base-station:hover {
  background-color: #f1f1f1;
}

.base-station img {
  height: 55px;
  margin-left: 5px;
}

.base-station span {
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-align: center;
  margin-top: 5px;
}

.base-station .status-icons {
  position: absolute;
  top: 5px;
  right: 3px;
}

.base-station .status-icons i {
  padding: 5px;
  display: block;
  text-align: center;
  color: #aaaaaa;
}

.base-station .status-icons i.on {
  color: red;
}

.panel-preview.evacuation {
  text-align: center;
  margin: 0px;
  padding: 2px 5px;
}

.evacuation-card .row {
  padding: 25px;
}

.panel-preview.evacuation div[id^='line'] {
  font-size: 20px;
}

/*  Present control */

.right-info.presence-control {
  padding: 20px 10px 10px;
  width: 275px;
}

.color-content-list {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid #000 !important;
  border-radius: 4px;
  margin-right: 5px;
}

.right-menu-list > li,
#worker-list > li,
#equipment-list > li {
  display: table;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.right-menu-list > li > *,
#worker-list > li > *,
#equipment-list > li > * {
  display: table-cell;
}

.right-menu-list > li > .staff-list-image,
#worker-list > li > .staff-list-image,
#equipment-list > li > .staff-list-image {
  width: 65px;
  border-right: 1px solid #cccccc;
}

.right-menu-list > li > .staff-list-image img,
#worker-list > li > .staff-list-image img,
#equipment-list > li > .staff-list-image img {
  vertical-align: middle;
  margin: 5px auto;
}

.right-menu-list > li > :nth-child(2),
#worker-list > li > :nth-child(2),
#equipment-list > li > :nth-child(2) {
  padding: 5px 10px;
  vertical-align: top;
  text-align: left;
}

.right-menu-list > li > :nth-child(2) > *,
#worker-list > li > :nth-child(2) > *,
#equipment-list > li > :nth-child(2) > * {
  display: block;
}

.right-menu-list > li > :nth-child(2) > :first-child,
#worker-list > li > :nth-child(2) > :first-child,
#equipment-list > li > :nth-child(2) > :first-child {
  text-transform: uppercase;
  font-weight: 600;
}

.right-menu-list > li > :nth-child(2) > :nth-child(2),
#worker-list > li > :nth-child(2) > :nth-child(2),
#equipment-list > li > :nth-child(2) > :nth-child(2) {
  color: #666;
  font-size: 12px;
}

div#assets_staff {
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

#assets_staff > span {
  text-transform: uppercase;
  border-bottom: 1px solid red;
  display: block;
  font-weight: 600;
  line-height: 25px;
}

#assets_staff > .content-data > span {
  text-align: left;
  text-transform: uppercase;
  display: block;
  padding: 10px 5px;
}

.presence-wrapper {
  display: table;
  width: 80%;
  margin: 45px auto 22px auto;
}

.presence-wrapper > div {
  display: table-cell;
  width: 50%;
  padding: 5px 15px;
}

.presence-indicator,
.intruder-indicator {
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.presence-indicator .title-indicator,
.intruder-indicator .title-indicator {
  display: block;
  font-size: 16px;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  background-color: #cccccc;
}

.presence-indicator img,
.intruder-indicator img {
  max-height: 95px;
  padding: 8px 5px;
  display: block;
  margin: 10px auto;
}

.presence-indicator .number-indicator,
.intruder-indicator .number-indicator {
  display: block;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  padding: 5px;
  font-weight: 200;
  border-top: 1px solid #cccccc;
}

.presence-control-disabled {
  position: relative;
  outline: 2px solid #666;
}

.presence-control-disabled:before {
  content: '';
  display: block;
  z-index: 300;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  background-color: rgba(100, 100, 100, 0.4);
}

.presence-control-disabled .card-body {
  background-color: #c3c3c3;
}

.presence-control .row,
.access-control .row,
.card-ventilation .row {
  width: 100%;
  height: 100%;
}

.presence-control .row > div,
.access-control .row > div {
  padding: 6% 10px;
}

.presence-indicator:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}

.presence-indicator:hover .title-indicator {
  background-color: #666666;
  color: white;
}

.process-indicator {
  margin: auto;
  padding: 5px;
  width: 75%;
  font-size: 20px;
  background-color: #e5e5e5;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  display: none;
}

.process-indicator.red {
  background-color: #f00;
  color: white !important;
}

.card-body.presence-control {
  background-image: url('../img/tunnel_gray.svg');
  background-repeat: no-repeat;
  background-size: 240px;
  background-position: 50% 50%;
}

.card-body.access-control {
  background-image: url('../img/nokas_gray.svg');
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: 50% 50%;
}

.card-ventilation.image-ap {
  margin: 0 auto;
  display: block;
  width: 250px;
}

.presence-control .config-btn,
.config-btn,
.access-control .config-btn,
.config-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  border: 1px solid #cccccc;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 10;
}

.presence-control .config-btn:hover,
.config-btn:hover,
.access-control .config-btn:hover,
.config-btn:hover {
  background-color: #f1f1f1;
}

.presence-control .config-btn.right,
.config-btn.right,
.access-control .config-btn.right {
  left: auto;
  right: 10px;
}

.presence-indicator.intruder-indicator,
.access-indicator.intruder-indicator {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 60px;
  height: 70px;
  background-color: red;
  border: 0px;
}

.card-body.presence-control,
.card-body.access-control {
  padding: 0px 40px;
}

.presence-indicator.intruder-indicator img {
  margin: 0px;
  padding: 0px;
  max-height: 45px;
  padding: 5px;
}

.presence-wrapper > div.presence-indicator.intruder-indicator {
  padding: 0px 5px;
}

.presence-wrapper > div.presence-indicator.intruder-indicator:hover {
  background-color: #666666;
}

.presence-indicator.intruder-indicator .number-indicator {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  border-top: 0px;
  color: white;
  padding-top: 0px;
  padding-bottom: 10px;
}

.right-menu-list#intruder-list > li:hover {
  cursor: pointer;
  background-color: #cccccc;
}

.right-menu-list > li > .intruder-list-image img {
  margin-top: 5px;
  width: 30px;
}

.right-menu-list > li#intruder {
  display: table;
}

span.intruder-number {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 570px) {
  .card-body.presence-control {
    padding: 0px;
  }

  .presence-wrapper > div {
    display: block;
    width: 100%;
    padding: 5px 15px;
  }

  .presence-indicator img {
    max-height: 80px;
    padding: 8px 5px;
    display: block;
    margin: 0px auto;
  }

  .presence-indicator.intruder-indicator {
    top: 10px;
    right: 10px;
    width: auto;
    height: auto;
  }

  .presence-indicator.intruder-indicator img {
    display: inline-block;
    width: 30px;
  }

  .presence-indicator.intruder-indicator .number-indicator {
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
    padding-bottom: 0px;
    margin-top: -4px;
  }
}

/* Fan control */

.highcharts-figure .chart-container {
  width: 100%;
  height: 300px;
}

.highcharts-figure,
.highcharts-data-table table {
  width: 100%;
  margin: 0 auto;
}

tspan {
  font-weight: 600;
}

.highcharts-figure {
  width: 100%;
  margin: -40px auto -40px;
}

.fan-image {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100px;
  opacity: 0.3;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker {
  top: 180px !important;
}

rect.highcharts-background {
  fill: transparent;
}

@media (max-width: 600px) {
  .highcharts-figure,
  .highcharts-data-table table {
    width: 100%;
  }

  .highcharts-figure .chart-container {
    width: 300px;
    float: none;
    margin: 0 auto;
  }
}

.slidecontainer {
  width: 87%;
  margin: 15px auto;
  z-index: 200;
}

.custom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
  background: #f1f1f1;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.custom-slider:hover {
  opacity: 1;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #ff0000;
  border-radius: 50%;
  cursor: pointer;
}

.custom-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #ff0000;
  border-radius: 50%;
  cursor: pointer;
}

.custom-slider:disabled::-webkit-slider-thumb {
  background: #666666 !important;
}

div#slider-wrapper {
  padding: 25px 15px 15px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.slidecontainer > span {
  margin-top: 5px;
}

div#slider-wrapper > span {
  font-size: 18px;
}

div#slider-wrapper > :first-child {
  text-transform: uppercase;
  font-weight: 700;
}

div#slider-wrapper > :not(:first-child) {
  font-weight: 200;
  font-size: 20px;
}

div#slider-wrapper > #value {
  margin-left: 10px;
}

.switcher-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 500;
}

.switcher-wrapper span {
  display: inline-block;
  font-size: 16px;
}

.switcher-wrapper.comunication {
  position: relative;
  margin-bottom: 20px;
}

table.gas-table {
  border-spacing: 5px;
  border-collapse: separate;
  margin-bottom: 0px;
  table-layout: fixed;
}

table.gas-table tbody td {
  padding: 5px 2px;
  text-align: center;
  border: 0px;
  margin-bottom: 5px;
  border-radius: 5px;
}

table.gas-table tbody td:not(:first-child) {
  border: 2px solid rgba(0, 0, 0, 0.09);
}

table.gas-table tbody td sup {
  color: white;
}

table.gas-table tbody td.orange {
  background-color: orange;
  color: white;
}

table.gas-table tbody td.green {
  background-color: #8cc63f;
  color: white;
}

table.gas-table tbody td.red {
  background-color: #ed1c24;
  color: white;
}

table.gas-table tbody td.gray {
  background-color: #696969;
  color: white;
}

/*  MAPPING ZONES */

.card.card-zone,
.card.card-map {
  position: relative;
}

.card.card-map {
  height: calc(100vh - 170px);
}

.card.card-zone .card-header {
  position: absolute;
  top: 2px;
  left: 2px;
}

.card-map .card-header {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 200;
  border: 1px solid white;
}

/* PLANNING SETTINGS */

.date-settings .ui-datepicker {
  width: 99%;
  padding: 0;
}

.date-settings .ui-widget {
  font-size: 0.9em;
}

.date-settings .ui-datepicker table {
  font-size: 0.7em;
}

.date-settings .ui-widget.ui-widget-content {
  border: 0px;
  border-radius: 0px;
}

.date-settings .ui-state-highlight,
.date-settings .ui-widget-content .ui-state-highlight,
.date-settings .ui-widget-header .ui-state-highlight {
  border: 1px solid white;
  background: #ff0000;
  color: white;
}

.date-settings .ui-state-highlight a,
.date-settings .ui-widget-content .ui-state-highlight a,
.date-settings .ui-widget-header .ui-state-highlight a {
  color: #ffffff;
  background-color: red;
  border: 0px;
}

.date-settings .ui-state-active,
.ui-widget-content .ui-state-active,
.date-settings .ui-widget-header .ui-state-active,
.date-settings a.ui-button:active,
.date-settings .ui-button:active,
.date-settings .ui-button.ui-state-active:hover {
  border: 1px solid #ffffff;
  background: #ffffff;
  font-weight: normal;
  color: #666666;
  box-shadow: none;
}

.date-settings .ui-datepicker table {
  border: 1px solid #f1f1f1;
  table-layout: fixed;
}

.date-settings .ui-datepicker td {
  height: 77px;
}

.date-settings .ui-datepicker td {
  border: 1px solid #e6e6e6;
  position: relative;
  padding: 0px;
}

.date-settings .ui-datepicker td:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}

.date-settings .ui-datepicker td.ui-state-highlight:hover {
  color: white;
  border: 0px;
  background-color: #990000;
}

.date-settings .ui-state-default,
.date-settings .ui-widget-content .ui-state-default,
.date-settings .ui-widget-header .ui-state-default,
.date-settings .ui-button,
html .date-settings .ui-button.ui-state-disabled:hover,
html .date-settings .ui-button.ui-state-disabled:active {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 14px;
  font-weight: 600;
  padding: 0px;
}

.ui-datepicker-calendar tbody tr td {
  vertical-align: text-top;
}

.date-settings .ui-datepicker td .cell-data,
.date-settings .ui-datepicker td .cell-data-mat {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
}

.date-settings .ui-datepicker td .ui-state-default {
  background-color: transparent;
  border: 0px;
  position: relative;
  top: 0;
  text-align: right;
}

.date-settings .ui-datepicker td .ui-state-default:hover {
  color: inherit;
}

.date-settings .ui-datepicker td .ui-state-highlight {
  color: red;
}

.date-settings .ui-datepicker td.ui-state-highlight .ui-state-highlight {
  color: white !important;
}

.date-settings .ui-datepicker td .cell-data span,
.date-settings .ui-datepicker td .cell-data-mat span {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0px;
  border-radius: 3px;
  margin: 2px;
}

.date-settings .ui-datepicker td .cell-data-mat > span:nth-child(odd) {
  background-color: rgba(41, 170, 226, 0.5) !important;
  color: #666666 !important;
}

.date-settings .ui-datepicker td .cell-data > span:nth-child(1) {
  background-color: rgba(41, 170, 226, 0.5);
}

.date-settings .ui-datepicker td .cell-data > span:nth-child(2),
.date-settings .ui-datepicker td .cell-data > span:nth-child(3) {
  display: inline-block;
  width: 46%;
  margin: 0px;
}

.date-settings .ui-datepicker td .cell-data > span:nth-child(2) {
  background-color: rgba(139, 0, 0, 0.3);
  margin-right: 2px;
}

.date-settings .ui-datepicker td .cell-data > span:nth-child(3) {
  background-color: rgba(139, 198, 63, 0.5);
}

.date-settings .ui-datepicker td .cell-data > span:nth-child(4) {
  background-color: rgba(102, 102, 102, 0.5);
}

.date-settings .ui-datepicker td .cell-data-mat > span:nth-child(even) {
  background-color: rgba(255, 255, 0, 0.3) !important;
  color: #666666 !important;
}

.date-settings .ui-datepicker td.ui-state-highlight .cell-data span,
.date-settings .ui-datepicker td.ui-state-highlight .cell-data-mat span {
  color: white;
  border: 0px;
  background-color: red;
}

.date-settings .ui-datepicker td.ui-state-highlight:hover .cell-data span,
.date-settings .ui-datepicker td.ui-state-highlight:hover .cell-data-mat span {
  color: white;
  border: 0px;
  background-color: #990000;
}

.ui-button .ui-icon {
  display: none;
}

.report-acc-header {
  line-height: 50px;
  font-size: 20px;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
}

.report-acc-header:hover {
  border-left: #f00 solid 5px;
  padding-left: 10px;
}

.report-acc-header:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

.active .report-acc-header:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

.date-settings-mat td {
  background-color: #fff !important;
  border: 1px solid #e6e6e6 !important;
}

.date-settings-mat td a,
.date-settings-mat .ui-datepicker td.ui-state-highlight .ui-state-highlight {
  color: #000 !important;
}

/*  CCTV  */

#imageModal img,
#cameraModal img {
  display: block;
  width: 100%;
  height: auto;
}

h1.camera-title,
h1.image-title {
  text-align: center;
  text-transform: uppercase;
}

#btn-go-live,
#live-video {
  position: absolute;
  left: 25px;
  top: 30px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 1000;
}

#live-video {
  right: 25px;
  top: 20px;
  color: white;
  background-color: transparent;
}

#btn-go-live:hover {
  background-color: #f1f1f1;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-top: 55.6%;
}

#video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.last-week-recordings {
  border: 1px solid #e6e6e6;
  margin: 10px 0px 5px;
  padding: 10px;
}

.last-week-recordings > span {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

#btn-go-live:before {
  content: '';
  float: left;
  width: 15px;
  height: 15px;
  background-color: red;
  margin-right: 10px;
  margin-top: 3px;
  border-radius: 7.5px;
}

#live-video:before {
  content: '';
  float: left;
  width: 15px;
  height: 15px;
  background-color: red;
  margin-right: 10px;
  margin-top: 3px;
  border-radius: 7.5px;
}

.select-multiple {
  background-color: #f1f1f1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  margin-bottom: 10px;
}

.select-multiple ul {
  margin-bottom: 0px;
  padding: 5px;
}

.select-multiple input[type='checkbox'] {
  display: none;
}

.select-multiple label {
  cursor: pointer;
  display: block;
  margin: 2px;
  padding: 10px 20px;
  outline-style: none;
  width: 100%;
}

.select-multiple input[type='checkbox'] + label:hover {
  background-color: #ff0000;
  color: white;
}

.select-multiple input[type='checkbox']:checked + label {
  background: #666666;
  color: white;
}

input.role_meta.child + label {
  padding-left: 25px;
}

input.role_meta.child + label:before {
  content: '-';
  margin-right: 15px;
}

.mhArrowLine {
  stroke: rgb(54, 105, 201);
  stroke-width: 2;
  marker-start: url(#markerDot);
  marker-end: url(#markerArrow);
}

.mapboxgl-ctrl-span {
  display: block;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 0px !important;
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-pitch0 {
  background-image: url('../img/pitch_0.svg');
}

.mapboxgl-ctrl-icon > .mapboxgl-ctrl-pitch60 {
  background-image: url('../img/pitch_60.svg');
}

.mapboxgl-slider {
  -webkit-appearance: none;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  transform: rotate(270deg);
  height: 16px;
  width: 100px !important;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: -35px;
  margin-right: -41px;
}

.mapboxgl-slider:hover {
  opacity: 1;
}

.mapboxgl-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #ff0000;
  cursor: pointer;
}

.mapboxgl-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ff0000;
  cursor: pointer;
}

.crud-list th td,
.crud-list tr td {
  text-align: center;
}

.mapboxgl-ctrl-top-right {
  z-index: 999 !important;
}

.ui-datepicker-current-day {
  background-color: #dfdfdf;
}

div#loading_wrapper {
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

div#loading_wrapper #loading-spinner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.staff-data > span.tag {
  font-size: 12px;
  font-weight: 100;
}

tr.auto {
  color: red;
  background-color: #ffdfdf;
}

.form-row .select-options li {
  white-space: nowrap;
}

ul.select-options {
  overflow-x: hidden;
}

.role-list {
  padding: 6px;
  outline: 1px solid #e6e6e6;
  margin: 8px 0px;
}

h1.no-results {
  text-align: center;
  font-style: italic;
}

.align_bottom_row {
  display: flex;
  align-items: flex-end;
}

.modalLoading {
  opacity: 0.8;
}

/* Ventilation control */

#sked-ventilation .sked-tape__location {
  background-color: #f1f1f1;
}

#sked-ventilation .sked-tape__location[title='Failure'],
#sked-ventilation .sked-tape__location[title='Saturday'],
#sked-ventilation .sked-tape__location[title='Sunday'] {
  background-color: #666666;
}

#sked-ventilation
  .sked-tape__location[title='Failure']
  .sked-tape__location-text,
#sked-ventilation
  .sked-tape__location[title='Saturday']
  .sked-tape__location-text,
#sked-ventilation
  .sked-tape__location[title='Sunday']
  .sked-tape__location-text {
  color: white;
}

#sked-ventilation .sked-tape__event-row {
  height: 35px;
}

#sked-ventilation .sked-tape__center {
  font-size: 11px;
}

#sked-ventilation .sked-tape__location {
  line-height: 35px;
  height: 35px;
}

#sked-ventilation .sked-tape__event {
  border: 2px solid #ff0000;
  background-color: #ff7777;
}

#sked-modal .modal-dialog {
  width: 80%;
  margin: 30px auto;
}

ul.weekly-shedule {
  margin-top: 30px;
}

ul.weekly-shedule li {
  display: inline;
  padding-left: 10px;
}

ul.weekly-shedule li input {
  display: none;
}

ul.weekly-shedule li label {
  position: relative;
  padding-left: 15px;
}

ul.weekly-shedule li span:before {
  content: '';
  display: block;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border: 1px solid black;
  position: absolute;
  top: 5px;
  left: 0px;
}

ul.weekly-shedule li span {
  color: #666666;
}

ul.weekly-shedule li input:checked + span:after {
  content: '';
  top: 3px;
  left: 3px;
  position: absolute;
  width: 7px;
  height: 12px;
  border: solid red;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

label.auto-switch:hover {
  cursor: pointer;
}

label.auto-switch > input {
  display: none;
}

label.auto-switch > input + span {
  color: #666666;
  border: 1px solid #666666;
  padding: 1px 6px 2px;
  border-radius: 5px;
  line-height: 15px;
  font-size: 11px;
  position: absolute;
  top: 15px;
  right: 80px;
}

label.auto-switch > input + span:hover {
  background-color: #d1d1d1;
}

label.auto-switch > input:checked + span {
  color: white;
  background-color: red;
  border: 1px solid red;
}

label.auto-switch > input:checked + span:hover {
  background-color: #990000;
  border: 1px solid #990000;
}

.new-sked-wrapper {
  border: 1px solid #d1d1d1;
  margin-top: 15px;
  padding: 35px 10px 10px;
  position: relative;
}

.new-sked-wrapper .sked-title {
  padding: 5px 10px;
  position: absolute;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  top: 0;
  left: 0;
  width: 100%;
  background: #eeeeee;
}

.quantity {
  display: block;
  width: 235px;
  text-align: right;
  float: right;
}

.quantity:before {
  content: 'POWER';
  position: relative;
  top: 0px;
  left: 5px;
  display: block;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.quantity input {
  width: 175px;
  height: 40px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #666666;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.quantity input:after {
  content: '%';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid red;
  top: 0;
  left: 0;
  position: absolute;
}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 40px;
}

.quantity-nav > div {
  color: white;
  font-size: 25px;
  border: 0px;
  width: 35px;
  line-height: 20px;
  background-color: #666666;
}

.quantity-nav > div:hover {
  background-color: red;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
  border-radius: 0px 5px 0px 0px;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: 0px;
  height: 50%;
  border-radius: 0px 0px 5px 0px;
}
F

/* Auscultation docket */
.tunnel-container {
  padding: 20px 0px;
  margin: 0 auto;
  max-width: 500px;
}

.convergence-list > li {
  display: table;
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 2px #00000063;
  margin-bottom: 10px;
  border-left: 8px solid grey;
  border-radius: 4px;
  table-layout: fixed;
}

.convergence-list > li.b1b2 {
  border-color: #34495e;
}

.convergence-list > li.b1b3 {
  border-color: #19bd9b;
}

.convergence-list > li.b2b3 {
  border-color: #9b58b7;
}

.convergence-list > li.b1b4 {
  border-color: #e84c3d;
}

.convergence-list > li.b1b5 {
  border-color: #e67f22;
}

.convergence-list > li.b4b5 {
  border-color: #f2c40f;
}

.convergence-list > li > div {
  display: table-cell;
  vertical-align: middle;
}

.convergence-list > li > div:not(:first-child) {
  text-align: center;
}

.convergence-list > li > div:not(:first-child) > span {
  display: block;
  color: #666666;
}

.convergence-list .convergence-title {
  display: block;
  font-size: 25px;
  font-weight: 600;
  color: #666666;
}

.convergence-list .convergence-subtitle {
  font-style: italic;
  color: #666666;
}

.convergence-list .convengence-value-title {
  text-transform: uppercase;
}

/* SVG Auscultation*/
.st0 {
  fill: #ffffff;
}

.st1 {
  fill: #ffffff;
  stroke: #808080;
  stroke-width: 14;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: #e84c3d;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: #9b58b7;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st4 {
  fill: none;
  stroke: #f2c40f;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st5 {
  fill: none;
  stroke: #19bd9b;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st6 {
  fill: none;
  stroke: #e67f22;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st7 {
  fill: none;
  stroke: #34495e;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st8 {
  fill: none;
}

.st9 {
  fill: none;
  stroke: #808080;
  stroke-width: 14;
  stroke-miterlimit: 10;
}

.st10 {
  enable-background: new;
}

.st11 {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}

.st13 {
  font-size: 18px;
  text-anchor: middle;
}

/*Convergence Report*/

.changeTunel {
  cursor: pointer;
}

.convergence-width-lg {
  min-width: 110px !important;
  padding: 5px 5px !important;
}

.convergence-width-md {
  min-width: 90px !important;
  padding: 5px 5px !important;
}

.convergence-width-sm {
  min-width: 70px !important;
  padding: 5px 5px !important;
}

.error_obligatory_field {
  color: red;
}

.read-select {
  background-color: #f1f1f1;
}

.concrete-table-title {
  padding: 10px 20px;
  background: #e6e6e6;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

.performance-detail-wrapper.scroll {
  max-height: 300px;
  overflow-y: auto;
  position: relative;
  outline: 1px solid #e6e6e6;
}

.performance-detail-wrapper.pad {
  padding: 10px 10px 20px;
}

.performance-detail-wrapper.scroll .performance-detail > div {
  padding: 5px 10px;
}

.performance-resume.squared {
  border-radius: 0px;
  border: 1px solid #e6e6e6;
}

span.docket-style {
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  padding: 2px 15px;
  font-size: 15px;
  line-height: 15px;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
}

.form-row.center {
  text-align: center;
}

.form-row.right {
  text-align: right;
}

span.docket-date {
  display: inline-block;
  width: 100%;
  text-align: right;
  font-size: 15px;
  font-weight: 200;
  padding: 5px;
}

.concrete-data {
  margin: -10px 0px 10px;
  outline: 1px solid #e6e6e6;
  padding: 15px 0px 0px;
}

.bootstrap-datetimepicker-widget .picker-switch td span {
  background-color: red;
}

.bootstrap-datetimepicker-widget .picker-switch td span:before {
  color: white;
}

.bootstrap-datetimepicker-widget .picker-switch td span:hover {
  background-color: #666666;
}

.text-markers-area {
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  border: 2px solid #000264;
}

.text-markers-area > span.name_area {
  font-weight: 800;
}

.text-markers-area > span.name_rig {
  font-size: 13px;
}

.check-kml {
  color: green !important;
  cursor: pointer;
}

.uncheck-kml {
  color: #afb0af !important;
  cursor: pointer;
}

.remove-kml {
  color: #d71931 !important;
  cursor: pointer;
}

.divBottom {
  float: none !important;
  display: inline-block;
  vertical-align: bottom;
}

/* Intruder control */

.loading-image-wrapper {
  background-color: #f1f1f1;
  height: 320px;
  position: relative;
}

img.loading-image {
  position: relative;
  top: 125px;
  width: auto !important;
  margin: 0 auto;
}

.rtls_list {
  background-color: #f1f1f1;
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;
  border: 1px solid #666666;
}

.rtls_list > ul > li {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin: 5px 0px;
  background-color: white;
  display: table;
  width: 100%;
  padding: 10px 5px;
}

.rtls_list > ul > li > * {
  display: table-cell;
  vertical-align: middle;
  margin: 0px;
}

.rtls_list > ul > li > div > span:first-child {
  display: block;
  font-weight: bold;
}

.rtls_list > ul > li > div > span:not(:first-child) {
  text-transform: uppercase;
  font-size: 12px;
}

.rtls_list > ul > li > div > span:not(:first-child):before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 1px solid black;
  margin: 0px 2px 0px 5px;
}

.rtls_list > ul > li > div > span.on:not(:first-child):before {
  background-color: #8cc63f;
}

.rtls_list > ul > li > div > span.off:not(:first-child):before {
  background-color: #bb5454;
}
