.wrapper {
    display: flex;
    background-color: #e6e6e650;
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
    position: relative;

    i {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .more {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
    }
    .more.visible {
        max-height: 500px; /* Ajusta este valor según el contenido */
    }
}
