.confirmOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.5);
}

.confirmBox {
    padding: 10px 20px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    p {
        font-size: 16px;
        text-align: center;
        margin-bottom: 0px;

        & > * {
            margin-right: 5px;
        }

        &.error svg {
            fill: red;
        }

        &.info svg {
            fill: blue;
        }
        &.warn svg {
            fill: #faad14;
        }
        &.success svg {
            fill: green;
        }
    }
}
