.confirmOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.confirmBox {
    padding: 20px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90vw;
    p {
        padding: 5px;
        font-size: 18px;
        text-align: center;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & > * {
        margin-bottom: 10px;
    }
}
