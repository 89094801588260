.description {
  margin-bottom: 30px;
  & > div:first-child > div {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}
