.wrapper {
    display: flex;
    align-items: center;
    position: relative;

    i {
        font-size: 24px;
    }

    span {
        margin-left: 5px;
        opacity: 0;
        transition: 0.5s ease opacity;
        position: absolute;
        left: 30px;
        z-index: 10;
        background-color: white;
    }
}
