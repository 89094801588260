.button {
    padding: 15px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0px 5px;
    border-radius: 0px;
    display: inline-block;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    position: relative;

    &,
    &:active,
    &:focus,
    &:visited {
        outline: none;
        font-size: 14px;
    }

    &:not(.onlyIcon) i {
        color: white;
        margin-right: 20px;
    }
    &[disabled] {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: 0.65;
    }

    &.primary {
        background-color: red;
        color: white;

        & i {
            color: white;
        }
    }
    &.byDefault,
    &.white {
        background-color: #f1f1f1;
        color: black;
    }

    &.white {
        background-color: white;
        & i {
            color: red;
        }
    }
    &.byDefault {
        & i {
            color: black;
        }
    }
}

.spin,
.spin:focus {
    position: absolute !important;
    right: 50% !important;
    top: 50% !important;
    margin-right: -12px;
    margin-top: -12px;
    left: auto !important;
}
