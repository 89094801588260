.handler {
  border-radius: 50%;
  width: 15px !important;
  height: 15px !important;
  background-color: red;
  padding: 15px;
  display: flex !important;
  justify-content: center;
  align-items: center;

  & i {
    color: white;
    font-size: 14px;
  }
}

.handlers {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  height: 100%;
}
.removeButton {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  background: #f1f1f1;
  border-radius: 50%;
  width: 15px !important;
  height: 15px !important;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: #999999;
  }
}

.handlers > :nth-child(1) {
  position: relative;
  left: 20px;
}
.handlers > :nth-child(2) {
  position: relative;
  right: 20px;
}
.carousel-wrapper {
  position: relative;
}

.photoTaken {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border: 1px solid #f1f1f1;
  position: relative;
}
