@mixin text() {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;

  span {
    text-transform: none;
  }
}
.parameters {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & > p {
    @include text;
    margin: 0px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .parameter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    justify-content: center;
    border-radius: 10px;
    background-color: rgba(230, 230, 230, 0.3);
    margin-bottom: 10px;
    padding: 20px 20px;

    & > div {
      display: flex;
      align-items: center;

      & > * {
        flex: 1;
        font-size: 14px;
      }

      p:first-child {
        @include text;
        flex: 1;
      }

      &:last-child {
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
