.select {
    display: flex;
    min-height: 47px !important;

    & > div > span {
        display: flex;
        align-items: center;
    }

    & > span {
        background-color: red;
        min-height: 100% !important;
        width: 47px !important;
        top: 6px !important;
        right: 0px !important;
        justify-content: center;

        i {
            color: white;
        }
    }

    & > div {
        border-color: transparent;
        width: 100%;
        min-height: 47px;
        border-radius: 0px !important;
    }

    &:hover > div {
        border-color: transparent !important;
    }
}

.popup {
    & > div > div:nth-child(2) > div:first-child > div > div > div {
        align-items: center;
        font-size: 16px;
    }
}
