.wrapper {
  padding: 10px 20px;
  overflow-y: scroll;
  max-height: 80vh;

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: column;

    & > button {
      margin-bottom: 10px;
    }
  }

  .hiddenZone {
    opacity: 0;
    height: 0;
    transition: all ease 1s;

    &.visible {
      opacity: 1;
      height: 100%;
    }

    & > div {
      margin-bottom: 5px;
      margin-bottom: 10px;
    }
  }

  .sensors {
    & > p {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: #000;
      margin: 0px;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 10px;
      margin-bottom: 5px;
      margin-top: 15px;
    }
  }
}
