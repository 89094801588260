.wrapper {
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1024px;
  margin: 0px auto;

  .container {
    flex: 1;
    overflow-y: scroll;

    & > div {
      background-color: white;
      padding: 20px;
    }
  }

  .footer {
    flex: 0 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 15px 30px;
      text-transform: uppercase;
      letter-spacing: 2px;
      border-radius: 0px;
      min-width: 175px;
    }
  }
  .header {
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
    color: #666;
    font-weight: 400;
  }

  .section {
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.9em;
    font-weight: 700;
    &::before {
      content: "\2014";
      color: red;
      margin-right: 5px;
    }
    &::after {
      content: "\2014";
      color: red;
      margin-left: 5px;
    }
  }
}
